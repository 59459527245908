import _ from "lodash";

interface MinuteRecurrence {
  startDate: Date;
  repetitionDeadline: Date | null;
  shouldApplyRepetetionDeadline: boolean;
  interval: number;
}

export function generateISODurationString({
  months,
  weeks,
  days,
  hours,
  minutes,
}: // seconds,
{
  months: number;
  weeks: number;
  days: number;
  hours: number;
  minutes: number;
}) {
  const isoStringParts = [];

  if (months > 0) {
    isoStringParts.push(`${months}M`);
  }
  if (weeks > 0) {
    isoStringParts.push(`${weeks}W`);
  }
  if (days > 0) {
    isoStringParts.push(`${days}D`);
  }

  if (hours > 0 || minutes > 0) {
    const timeStringParts = [];
    if (hours > 0) {
      timeStringParts.push(`${hours}H`);
    }
    if (minutes > 0) {
      timeStringParts.push(`${minutes}M`);
    }

    isoStringParts.push(`T${timeStringParts.join("")}`);
  }

  return `P${isoStringParts.join("")}`;
}

export function generateMinuteRecurrence({
  startDate,
  repetitionDeadline,
  interval,
  shouldApplyRepetetionDeadline,
}: MinuteRecurrence): string {
  const startISOString = startDate.toISOString();
  const intervalStr = `PT${interval}M`;
  let endISOString = "";
  if (shouldApplyRepetetionDeadline && repetitionDeadline) {
    endISOString = `;UNTIL=${repetitionDeadline.toISOString()}`;
  }

  return `R/${startISOString}/${intervalStr}/FREQ=MINUTELY${endISOString}`;
}

export function generateMinuteRecurrenceForTypeOne({
  startDate,
  startMinute,
  interval,
  repetitionDeadline,
  shouldApplyRepetetionDeadline,
}: {
  startDate: Date;
  startMinute: string;
  interval: string;
  repetitionDeadline: Date | null;
  shouldApplyRepetetionDeadline: boolean;
}) {
  const startingMinute = Number(startMinute);
  const startMinuteDate = new Date(startDate);

  startMinuteDate.setHours(startMinuteDate.getHours(), startingMinute);

  if (startMinuteDate <= startDate) {
    startMinuteDate.setHours(
      startMinuteDate.getHours() + 1,
      startingMinute,
      0,
      0
    );
  }

  const recurrenceStart = startMinuteDate.toISOString();
  const recurrenceDuration = `PT${interval}M`;
  const recurrenceDeadline =
    shouldApplyRepetetionDeadline && repetitionDeadline
      ? `/${repetitionDeadline.toISOString()}`
      : "";
  return `R/${recurrenceStart}/${recurrenceDuration}${recurrenceDeadline}`;
}

export function generateMinuteRecurrenceForTypeTwo({
  startDate,
  selectedMinutes,
  repetitionDeadline,
  shouldApplyRepetetionDeadline,
}: {
  startDate: Date;
  selectedMinutes: Array<string>;
  repetitionDeadline: Date | null;
  shouldApplyRepetetionDeadline: boolean;
}) {
  const recurrenceStartDate = startDate.toISOString();
  const recurrenceSelectedMinutes = selectedMinutes.join(",");
  let recurrenceDeadlineStr = "";
  if (shouldApplyRepetetionDeadline && repetitionDeadline) {
    let recurrenceDeadline = repetitionDeadline.toISOString();
    recurrenceDeadlineStr = `;UNTIL=${recurrenceDeadline}`;
  }
  return `R/${recurrenceStartDate}/PT1M/BYMINUTE=${recurrenceSelectedMinutes}${recurrenceDeadlineStr}`;
}

export function parseMinuteRecurrenceRule(isoString: string, type: string) {
  const splittedIsoString = isoString.split("/");
  const startDate = new Date(splittedIsoString[1]);
  let shouldApplyRepetitionDeadline: boolean = false;
  let repetetionDeadLine: Date | null = null;
  let parsedValues: { interval?: string; selectedMinutes?: Array<string> } = {};
  if (type === "0") {
    const isRepetitionDeadlineApplied = isoString.includes("UNTIL=");
    shouldApplyRepetitionDeadline = isRepetitionDeadlineApplied;
    if (isRepetitionDeadlineApplied) {
      const repetetionDeadLineStr: string = splittedIsoString[
        splittedIsoString.length - 1
      ]
        .split(";")[1]
        .slice(6);
      repetetionDeadLine = new Date(repetetionDeadLineStr);
    }
  } else if (type === "1") {
    const isRepetitionDeadlineApplied = splittedIsoString.length === 4;
    const regex = /^PT(\d+)M$/;
    const match = splittedIsoString[2].match(regex);
    let interval: string = "00";
    if (match) {
      interval =
        parseInt(match[1]) < 10
          ? `0${parseInt(match[1])}`
          : `${parseInt(match[1])}`;
    }
    shouldApplyRepetitionDeadline = isRepetitionDeadlineApplied;
    repetetionDeadLine = isRepetitionDeadlineApplied
      ? new Date(splittedIsoString[3])
      : null;
    parsedValues = {
      interval: interval,
    };
  } else if (type === "2" || type === "3") {
    const selectedMinutes = splittedIsoString
      .find((str: string) => str.includes("BYMINUTE="))
      ?.split(";")[0]
      .slice(9)
      .split(",");
    const isRepetitionDeadlineApplied = isoString.includes("UNTIL=");

    if (isRepetitionDeadlineApplied) {
      const repetetionDeadLineStr: string = splittedIsoString[
        splittedIsoString.length - 1
      ]
        .split(";")[1]
        .slice(6);
      repetetionDeadLine = new Date(repetetionDeadLineStr);
    }
    shouldApplyRepetitionDeadline = isRepetitionDeadlineApplied;
    parsedValues = {
      selectedMinutes: !!selectedMinutes ? selectedMinutes : [],
    };
  }
  return {
    startDate,
    repetetionDeadLine,
    shouldApplyRepetitionDeadline,
    ...parsedValues,
  };
}

export function parseHourlyRecurrenceRule(isoString: string, type: string) {
  const splittedIsoString = isoString.split("/");
  const startDate = new Date(splittedIsoString[1]);
  let shouldApplyRepetitionDeadline: boolean = false;
  let repetetionDeadLine: Date | null = null;
  let parsedValues: { interval?: string; selectedHours?: Array<string> } = {};
  if (type === "0") {
    const isRepetitionDeadlineApplied = isoString.includes("UNTIL=");
    shouldApplyRepetitionDeadline = isRepetitionDeadlineApplied;
    if (isRepetitionDeadlineApplied) {
      const repetetionDeadLineStr: string | undefined = splittedIsoString[
        splittedIsoString.length - 1
      ]
        .split(";")
        .find((part: string) => part.includes("UNTIL="))
        ?.slice(6);
      if (repetetionDeadLineStr)
        repetetionDeadLine = new Date(repetetionDeadLineStr);
    }
  } else if (type === "1") {
    const isRepetitionDeadlineApplied = isoString.includes("UNTIL=");
    const regex = /^PT(\d+)H$/;
    const match = splittedIsoString[2].match(regex);
    let interval: string = "00";
    if (match) {
      interval =
        parseInt(match[1]) < 10
          ? `0${parseInt(match[1])}`
          : `${parseInt(match[1])}`;
    }
    shouldApplyRepetitionDeadline = isRepetitionDeadlineApplied;

    if (isRepetitionDeadlineApplied) {
      const repetetionDeadLineStr: string | undefined = splittedIsoString[
        splittedIsoString.length - 1
      ]
        .split(";")
        .find((part: string) => part.includes("UNTIL="))
        ?.slice(6);
      if (repetetionDeadLineStr)
        repetetionDeadLine = new Date(repetetionDeadLineStr);
    }
    parsedValues = {
      interval: interval,
    };
  } else if (type === "2" || type === "3") {
    const selectedHours = splittedIsoString
      .find((str: string) => str.includes("BYHOUR="))
      ?.split(";")
      .find((str: string) => str.includes("BYHOUR="))
      ?.slice(7)
      .split(",");
    const isRepetitionDeadlineApplied = isoString.includes("UNTIL=");

    if (isRepetitionDeadlineApplied) {
      const repetetionDeadLineStr: string | undefined = splittedIsoString[
        splittedIsoString.length - 1
      ]
        .split(";")
        .find((str: string) => str.includes("UNTIL="))
        ?.slice(6);
      if (repetetionDeadLineStr)
        repetetionDeadLine = new Date(repetetionDeadLineStr);
    }
    shouldApplyRepetitionDeadline = isRepetitionDeadlineApplied;
    parsedValues = {
      selectedHours: !!selectedHours ? selectedHours : [],
    };
  }
  return {
    startDate,
    repetetionDeadLine,
    shouldApplyRepetitionDeadline,
    ...parsedValues,
  };
}

export function generateDurationIsoString(date: Date) {
  const hours = new Date(date).getHours();
  const minutes = new Date(date).getMinutes();
  const seconds = new Date(date).getSeconds();
  return `PT${hours}H${minutes}M${seconds}S`;
}

export function generateIsoStringFromDuration(
  duration: string,
  startDate: Date
) {
  const regex = /^P((\d+)D)?T?((\d+)H)?((\d+)M)?((\d+)S)?$/;
  const matches = duration.match(regex);
  if (!matches) {
    throw new Error("Invalid duration string");
  }

  const hours = parseInt(matches[4]) || 0;
  const minutes = parseInt(matches[6]) || 0;
  const seconds = parseInt(matches[8]) || 0;

  const finalDate = new Date();
  finalDate.setHours(hours, minutes, seconds);

  return finalDate;
}
export function parseISODurationString(durationString: string) {
  const durationRegex =
    /^P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d+)?)S)?)?$/;
  const matches = durationString.match(durationRegex);

  if (!matches) {
    throw new Error(`Invalid duration string: ${durationString}`);
  }

  const [, , months, weeks, days, hours, minutes] = matches;

  return {
    months: parseInt(months) || 0,
    weeks: parseInt(weeks) || 0,
    days: parseInt(days) || 0,
    hours: parseInt(hours) || 0,
    minutes: parseInt(minutes) || 0,
    // seconds: parseFloat(seconds) || 0,
  };
}

export function generateHourlyRecurrence({
  startDate,
  repetitionDeadline,
  shouldApplyRepetetionDeadline,
}: {
  startDate: Date;
  repetitionDeadline: Date | null;
  shouldApplyRepetetionDeadline: boolean;
}) {
  // Format the start and end dates in ISO 8601 format
  const formattedStartDate = startDate.toISOString();
  const formattedEndDate =
    shouldApplyRepetetionDeadline && repetitionDeadline
      ? repetitionDeadline.toISOString()
      : null;
  let endDateStr = "";
  if (formattedEndDate) {
    endDateStr += `;UNTIL=${formattedEndDate}`;
  }

  // Generate the ISO 8601 format hourly recurrence rule
  const recurrenceRule = `R/${formattedStartDate}/PT1H/FREQ=HOURLY${endDateStr}`;

  return recurrenceRule;
}

export function generateHourlyRecurrenceForTypeOne({
  startDate,
  repetitionDeadline,
  interval,
  startHour,
  shouldApplyRepetetionDeadline,
}: {
  startDate: Date;
  repetitionDeadline: Date | null;
  interval: string;
  startHour: string;
  shouldApplyRepetetionDeadline: boolean;
}) {
  let startingDate = new Date(startDate);
  startingDate.setHours(
    Number(startHour),
    startDate.getMinutes(),
    startDate.getSeconds(),
    startDate.getMilliseconds()
  );

  let isoStartDate = startingDate.toISOString();
  let isoEndDate =
    shouldApplyRepetetionDeadline && repetitionDeadline
      ? repetitionDeadline.toISOString()
      : null;

  let duration = `PT${interval}H`; //"PT" + interval.toString() + "H";

  let iso8601Format = `R/${isoStartDate}/${duration}/FREQ=HOURLY`;

  if (isoEndDate) {
    let isoEndDateTime = isoEndDate;
    iso8601Format += `;UNTIL=${isoEndDateTime}`;
  }

  return iso8601Format;
}

export function generateHourlyRecurrenceForTypeTwo({
  startDate,
  repetitionDeadline,
  selectedHours,
  shouldApplyRepetetionDeadline,
}: {
  startDate: Date;
  repetitionDeadline: Date | null;
  selectedHours: Array<string>;
  shouldApplyRepetetionDeadline: boolean;
}) {
  // Convert start and end dates to ISO 8601 format
  const startingTime = startDate.toISOString();
  const endingTime =
    shouldApplyRepetetionDeadline && repetitionDeadline
      ? repetitionDeadline.toISOString()
      : null;

  // Build the starting and ending time components of the recurrence rule
  //const startingTime = `${startISO}T${selectedHours[0].toString().padStart(2, '0')}:00:00`;
  //const endingTime = endISO ? `${endISO}T23:59:59` : null;

  // Build the duration component of the recurrence rule
  const duration = "PT1H";

  // Build the frequency and until components of the recurrence rule
  const frequency = `FREQ=HOURLY;BYHOUR=${selectedHours.join(",")}`;
  const until = endingTime ? `UNTIL=${endingTime}` : "";

  // Combine the components into a single ISO 8601 string
  const recurrence = `R/${startingTime}/${duration}/${frequency};${until}`;

  return recurrence;
}

export function generateDailyRecurrence({
  startDate,
  repetitionInterval,
  repetitionDeadline,
  shouldApplyRepetetionDeadline,
}: {
  startDate: Date;
  repetitionInterval: number;
  repetitionDeadline?: Date | null;
  shouldApplyRepetetionDeadline: boolean;
}) {
  const startDateStr = startDate.toISOString().replace(/\.\d{3}Z$/, "Z");
  const intervalStr = `P${repetitionInterval}D`;
  const deadlineStr =
    shouldApplyRepetetionDeadline && repetitionDeadline
      ? repetitionDeadline.toISOString().replace(/\.\d{3}Z$/, "Z")
      : null;

  let expression = `R/${startDateStr}/${intervalStr}`;
  if (deadlineStr) {
    expression += `/${deadlineStr}`;
  }

  return expression;
}

export function parseDailyRecurrence(recurrence: string) {
  const pattern = /^R\/(.+)\/P(\d+)D(\/(.+))?$/;
  const match = pattern.exec(recurrence);
  if (!match) {
    throw new Error(`Invalid daily recurrence string: ${recurrence}`);
  }

  const startDate = new Date(match[1]);
  const repetitionInterval = parseInt(
    match[2].substring(1, match[2].length - 1)
  );
  const repetitionDeadline = match[4] ? new Date(match[4]) : null;

  return {
    startDate,
    repetitionInterval,
    repetitionDeadline,
    shouldApplyRepetitionDeadline: !!repetitionDeadline,
  };
}

type DaysMap = {
  [key in string]: string;
};

export function generateWeeklyRecurrence({
  startDate,
  repetitionInterval,
  repetitionDays,
  repetitionDeadline,
  shouldApplyRepetetionDeadline,
}: {
  startDate: Date;
  repetitionInterval: number;
  repetitionDays: Array<string>;
  repetitionDeadline: Date | null;
  shouldApplyRepetetionDeadline: boolean;
}) {
  const startDateStr = startDate.toISOString().replace(/\.\d{3}Z$/, "Z");
  const intervalStr = `P${repetitionInterval}W`;
  const deadlineStr =
    shouldApplyRepetetionDeadline && repetitionDeadline
      ? repetitionDeadline.toISOString().replace(/\.\d{3}Z$/, "Z")
      : null;

  const daysMap: DaysMap = {
    monday: "MO",
    tuesday: "TU",
    wednesday: "WE",
    thursday: "TH",
    friday: "FR",
    saturday: "SA",
    sunday: "SU",
  };

  let daysStr = "";
  if (repetitionDays.length) {
    daysStr = repetitionDays
      .map((day: string) => daysMap[day.toLowerCase()])
      .join(",");

    daysStr = `BYDAY=${daysStr}`;
  }

  let expression = `R/${startDateStr}/${intervalStr}/`;
  expression += `FREQ=WEEKLY;${daysStr}`;
  if (deadlineStr) {
    expression += `;UNTIL=${deadlineStr}`;
  }

  return expression;
}

type WeeklyRecurrence = {
  startDate: Date;
  repetitionInterval: number;
  repetitionDays: string[];
  repetitionDeadline: Date | null;
  shouldApplyRepetitionDeadline: boolean;
};

export function parseWeeklyRecurrence(isoString: string): WeeklyRecurrence {
  const [, start, interval, freqDays] = isoString.split("/");
  const startDate = new Date(start);
  const repetitionInterval = Number(interval.slice(1, -1));
  let deadline = freqDays
    .split(";")
    .find((each) => each.includes("UNTIL="))
    ?.slice(6);

  const shouldApplyRepetitionDeadline = !!deadline;
  let repetitionDeadline: Date | null = null;
  let repetitionDays: string[] = [];

  const daysMap: DaysMap = {
    MO: "monday",
    TU: "tuesday",
    WE: "wednesday",
    TH: "thursday",
    FR: "friday",
    SA: "saturday",
    SU: "sunday",
  };

  const freqParts = freqDays.split(";");
  const freq = freqParts[0];
  if (freq === "FREQ=WEEKLY" && freqParts.length > 1) {
    const daysPart = freqParts[1];
    if (daysPart.startsWith("BYDAY=")) {
      repetitionDays = daysPart
        .slice(6)
        .split(",")
        .map((eachDay) => daysMap[eachDay]);
    }
  }

  if (shouldApplyRepetitionDeadline && deadline) {
    repetitionDeadline = new Date(deadline);
  }

  return {
    startDate,
    repetitionInterval,
    repetitionDays,
    repetitionDeadline,
    shouldApplyRepetitionDeadline,
  };
}

export function generateMonthlyRecurrence({
  startDate,
  repetitionDeadline,
  selectedMonths,
  selectedDays,
  shouldApplyRepetetionDeadline,
  shouldApplySelectedDaysOfMonthFilter,
}: {
  startDate: Date;
  repetitionDeadline: Date | null;
  selectedMonths: Array<number>;
  selectedDays: Array<string>;
  shouldApplyRepetetionDeadline: boolean;
  shouldApplySelectedDaysOfMonthFilter: boolean;
}) {
  const startDateStr = startDate.toISOString().replace(/\.\d{3}Z$/, "Z");
  let monthStr = "";
  if (selectedMonths) {
    monthStr = selectedMonths
      .map((month) => (month < 10 ? `0${month}` : `${month}`))
      .join(",");
    monthStr = `BYMONTH=${monthStr};`;
  }
  let daysStr = "";
  if (shouldApplySelectedDaysOfMonthFilter && selectedDays.length) {
    const daysMap: DaysMap = {
      monday: "MO",
      tuesday: "TU",
      wednesday: "WE",
      thursday: "TH",
      friday: "FR",
      saturday: "SA",
      sunday: "SU",
    };
    daysStr = selectedDays
      .map((day: string) => daysMap[day.toLowerCase()])
      .join(",");

    daysStr = `BYMONTHDAY=${daysStr};`;
  }

  const monthDayStr = `FREQ=MONTHLY;${daysStr}${monthStr}`;
  const deadlineStr =
    shouldApplyRepetetionDeadline && repetitionDeadline
      ? repetitionDeadline.toISOString().replace(/\.\d{3}Z$/, "Z")
      : null;

  let expression = `R/${startDateStr}/P1M/${monthDayStr}`;
  if (deadlineStr) {
    expression += `UNTIL=${deadlineStr}`;
  }

  return expression;
}

export function getRecurrenceRuleType(isoString: string) {
  let splittedIso = isoString.split("/");
  if (isoString.includes("FREQ=WEEKLY")) {
    return "Weekly";
  } else if (isoString.includes("FREQ=MONTHLY")) {
    return "Monthly";
  } else if (isoString.includes("FREQ=HOURLY")) {
    return "Hourly";
  } else if (
    splittedIso[2].includes("PT") &&
    splittedIso[2].charAt(splittedIso[2].length - 1) === "M"
  ) {
    return "Minutely";
  } else {
    return "Daily";
  }
}

interface MonthlyRecurrence {
  startDate: Date;
  repetitionDeadline: Date | null;
  selectedMonths: number[];
  selectedDays: string[];
  shouldApplyRepetetionDeadline: boolean;
  shouldApplySelectedDaysOfMonthFilter: boolean;
}

export function parseMonthlyRecurrence(
  recurrence: string
): MonthlyRecurrence | null {
  const regex = /^R\/(.+)\/P1M\/(.+)$/i;
  const match = recurrence.match(regex);
  if (!match) {
    return null;
  }

  const startDate = new Date(match[1]);
  const parts = match[2].split(";");

  let selectedMonths: number[] = [];
  let selectedDays: Array<string> = [];
  let repetitionDeadline: Date | null = null;
  let shouldApplyRepetetionDeadline = false;
  let shouldApplySelectedDaysOfMonthFilter = false;

  for (let i = 0; i < parts.length; i++) {
    const part = parts[i].trim();
    if (part === "FREQ=MONTHLY") {
      continue;
    }
    if (part.startsWith("BYMONTH=")) {
      selectedMonths = part
        .substr(9)
        .split(",")
        .map((m) => parseInt(m, 10));
    }
    if (part.startsWith("BYMONTHDAY=")) {
      selectedDays = part
        .substr(12)
        .split(",")
        .map((d) => {
          switch (d) {
            case "MO":
              return "monday";
            case "TU":
              return "tuesday";
            case "WE":
              return "wednesday";
            case "TH":
              return "thursday";
            case "FR":
              return "friday";
            case "SA":
              return "saturday";
            case "SU":
              return "sunday";
            default:
              return "";
          }
        });
      shouldApplySelectedDaysOfMonthFilter = true;
    }
    if (part.startsWith("UNTIL=")) {
      repetitionDeadline = new Date(part.substr(6));
      shouldApplyRepetetionDeadline = true;
    }
  }

  return {
    startDate,
    repetitionDeadline,
    selectedMonths,
    selectedDays: selectedDays,
    shouldApplyRepetetionDeadline,
    shouldApplySelectedDaysOfMonthFilter,
  };
}

export const getMinutesList = (min: number, max: number) => {
  if (min <= max) {
    let minutes = [];

    for (let i = min; i <= max; i++) {
      minutes.push(i < 10 ? `0${i}` : `${i}`);
    }

    return minutes;
  }
  return [];
};

export const isContinous = (list: Array<string>) => {
  const sortedArr = _.sortBy(list.map((str: string) => parseInt(str)));
  const uniqArr = _.sortedUniq(sortedArr);
  const isContinuous = _.every(
    uniqArr,
    (val: number, index: number, arr: number[]) => {
      if (index === 0) return true;
      return val - arr[index - 1] === 1;
    }
  );

  return isContinuous;
};

export const getMinutelyRecurrenceRuleType = (isoString: string) => {
  const splittedIso = isoString.split("/");
  if (isoString.includes("FREQ=MINUTELY") && splittedIso[2] === "PT1M") {
    return "0";
  } else if (isoString.includes("BYMINUTE=")) {
    const selectedMinutes = splittedIso[splittedIso.length - 1]
      .split(";")[0]
      .slice(9)
      .split(",");
    return isContinous(selectedMinutes) ? "3" : "2";
  }
  return "1";
};

export const getHourlyRecurrenceRuleType = (isoString: string) => {
  const splittedIso = isoString.split("/");
  if (isoString.includes("FREQ=HOURLY") && !isoString.includes("BYHOUR=")) {
    if (splittedIso[2] === "PT1H") {
      return "0";
    }
    return "1";
  } else if (isoString.includes("BYHOUR=")) {
    const selectedHours = splittedIso[splittedIso.length - 1]
      .split(";")
      .find((part: string) => part.includes("BYHOUR="))
      ?.slice(7)
      .split(",");
    return isContinous(selectedHours ? selectedHours : []) ? "3" : "2";
  }
  return "";
};

export const convertNumbersToStrings = (list: Array<number>) => {
  let listOfStrings: Array<string> = [];
  for (let i = 0; i < list.length; i++) {
    listOfStrings.push(list[i] < 10 ? `0${list[i]}` : `${list[i]}`);
  }
  return listOfStrings;
};

export const getHoursList = (min: number, max: number) => {
  if (min <= max) {
    let hours = [];

    for (let i = min; i <= max; i++) {
      hours.push(i < 10 ? `0${i}` : `${i}`);
    }

    return hours;
  }
  return [];
};

export const getList = (min: number, max: number) => {
  if (min <= max) {
    let numbers = [];

    for (let i = min; i <= max; i++) {
      numbers.push(i < 10 ? `0${i}` : `${i}`);
    }

    return numbers;
  }
  return [];
};

export const checkForContinousNumbers = (list: Array<number>) => {
  const sortedArr = _.sortBy(list);
  const uniqArr = _.sortedUniq(sortedArr);
  const isContinuous = _.every(
    uniqArr,
    (val: number, index: number, arr: number[]) => {
      if (index === 0) return true;
      return val - arr[index - 1] === 1;
    }
  );

  return { isContinuous, list: sortedArr };
};

export const generateCronExpression = ({
  selectedMonths,
  selectedDays,
  // daysInterval,
  selectedDaysOfTheMonth,

  selectedHours,
  // hoursInterval,
  // startHour,
  selectedMinutes,
}: // minutesInterval,
// startMinute,
{
  selectedMonths: Array<number>;
  selectedDays?: Array<number>;
  // daysInterval?: number;
  selectedDaysOfTheMonth?: Array<number>;
  // daysOfTheMonthInterval?: number;
  // startDay?: number;
  selectedHours?: Array<number>;
  // hoursInterval?: number;
  // startHour?: number;
  selectedMinutes?: Array<number>;
  // minutesInterval?: number;
  // startMinute?: number;
}) => {
  // Build the cron expression string
  let cronExpression = "";

  //minutes
  if (selectedMinutes && selectedMinutes.length) {
    if (selectedMinutes.includes(-1)) {
      cronExpression += "* ";
    } else if (selectedMinutes.length === 1) {
      cronExpression += `${selectedMinutes[0]} `;
    } else {
      const continousObject = checkForContinousNumbers(
        selectedMinutes.map((str: number) => {
          if (str === -1) return -1;
          else {
            return str;
          }
        })
      );
      if (continousObject.isContinuous) {
        cronExpression += `${continousObject.list[0]}-${
          continousObject.list[continousObject.list.length - 1]
        } `;
      } else {
        cronExpression += `${selectedMinutes.join(",")} `;
      }
    }
  } else {
    cronExpression += "* ";
  }

  // hours
  if (selectedHours && selectedHours.length) {
    if (selectedHours.includes(-1)) {
      cronExpression += "* ";
    } else if (selectedHours.length === 1) {
      cronExpression += `${selectedHours[0]} `;
    } else {
      const continousObject = checkForContinousNumbers(
        selectedHours.map((str: number) => {
          if (str === -1) return -1;
          else {
            return str;
          }
        })
      );
      if (continousObject.isContinuous) {
        cronExpression += `${continousObject.list[0]}-${
          continousObject.list[continousObject.list.length - 1]
        } `;
      } else {
        cronExpression += `${selectedHours.join(",")} `;
      }
    }
  } else {
    cronExpression += "* ";
  }

  // //days of the month

  if (selectedDaysOfTheMonth) {
    if (selectedDaysOfTheMonth.includes(-1)) {
      cronExpression += "* ";
    } else if (selectedDaysOfTheMonth.length === 1) {
      cronExpression += `${selectedDaysOfTheMonth[0]} `;
    } else if (selectedDaysOfTheMonth.length) {
      const continousObject = checkForContinousNumbers(selectedDaysOfTheMonth);
      if (continousObject.isContinuous) {
        cronExpression += `${continousObject.list[0]}-${
          continousObject.list[continousObject.list.length - 1]
        } `;
      } else if (selectedDaysOfTheMonth.length > 1) {
        cronExpression += selectedDaysOfTheMonth.join(",") + " ";
      } else {
        cronExpression += "* ";
      }
    } else {
      cronExpression += "* ";
    }
  }

  //months

  if (selectedMonths.length) {
    cronExpression += selectedMonths.includes(-1)
      ? "* "
      : selectedMonths.join(",") + " ";
  } else {
    cronExpression += "* ";
  }

  //days

  if (selectedDays && selectedDays.length) {
    if (selectedDays.includes(-1)) {
      cronExpression += "* ";
    } else if (selectedDays.length === 1) {
      cronExpression += `${selectedDays[0]} `;
    } else {
      const continousObject = checkForContinousNumbers(selectedDays);
      if (continousObject.isContinuous) {
        cronExpression += `${continousObject.list[0]}-${
          continousObject.list[continousObject.list.length - 1]
        } `;
      } else if (selectedDays.length > 1) {
        cronExpression += selectedDays.join(",") + " ";
      }
    }
  } else {
    // cronExpression += "*";
    // NOTE: days only which recurs particular day of the week
    cronExpression += "* ";
  }

  return cronExpression;
};

export const parseCronExpression = (cronExpression: string) => {
  const params = cronExpression.trim().split(" ");
  const selectedMonths: number[] = [];
  const selectedDays: number[] = [];
  // let daysInterval;
  const selectedDaysOfTheMonth: number[] = [];
  // let daysOfTheMonthInterval;
  // let startDay;
  const selectedHours: number[] = [];
  // let hoursInterval;
  // let startHour;
  const selectedMinutes: number[] = [];
  // let minutesInterval;
  // let startMinute;

  // Parse minutes
  if (params[0] === "*") {
    selectedMinutes.push(-1);
  } else if (params[0].includes(",")) {
    params[0]
      .split(",")
      .forEach((minute) => selectedMinutes.push(parseInt(minute)));
  } else if (params[0].includes("-")) {
    const [min, max] = params[0].split("-");
    const minutesList: string[] = getList(parseInt(min), parseInt(max));
    minutesList.forEach((minute: string) =>
      selectedMinutes.push(parseInt(minute))
    );
  } else if (params[0].includes("/")) {
    const minute: number = parseInt(params[0].split("/")[1]);
    selectedMinutes.push(minute);
  } else {
    // const [start, interval] = params[0].split("/");
    // startMinute = start;
    // minutesInterval = interval;
  }

  // Parse hours
  if (params[1] === "*") {
    selectedHours.push(-1);
  } else if (params[1].includes(",")) {
    params[1].split(",").forEach((hour) => selectedHours.push(parseInt(hour)));
  } else if (params[1].includes("-")) {
    const [min, max] = params[1].split("-");
    const hoursList: string[] = getList(parseInt(min), parseInt(max));
    hoursList.forEach((hour: string) => selectedHours.push(parseInt(hour)));
  } else if (params[1].includes("/")) {
    const hour: number = parseInt(params[1].split("/")[1]);
    selectedHours.push(hour);
  } else {
    // const [start, interval] = params[1].split("/");
    // startHour = start;
    // hoursInterval = interval;
  }

  // Parse days of week (ignore)

  //parse days of the month

  if (params[2] === "*") {
    selectedDaysOfTheMonth.push(-1);
  } else if (params[2].includes(",")) {
    params[2]
      .split(",")
      .forEach((day) => selectedDaysOfTheMonth.push(parseInt(day)));
  } else if (params[2].includes("-")) {
    const [min, max] = params[2].split("-");
    const selectedDays: string[] = getList(parseInt(min), parseInt(max));
    selectedDays.forEach((day) => selectedDaysOfTheMonth.push(parseInt(day)));
  } else if (params[2].includes("/")) {
    const selectedDay: number = parseInt(params[2].split("/")[1]);
    selectedDaysOfTheMonth.push(selectedDay);
  } else {
    // const [start, interval] = params[2].split("/");
    // startDay = start;
    // daysOfTheMonthInterval = interval;
  }

  // Parse months
  if (params[3] === "*") {
    selectedMonths.push(-1);
  } else if (params[3].includes(",")) {
    params[3]
      .split(",")
      .forEach((month) => selectedMonths.push(parseInt(month)));
  } else {
    selectedMonths.push(parseInt(params[3]));
  }

  // Parse days of month

  if (params[params.length - 1] === "*") {
    selectedDays.push(-1);
  } else if (params[params.length - 1].includes(",")) {
    params[params.length - 1]
      .split(",")
      .forEach((day) => selectedDays.push(parseInt(day)));
  } else if (params[params.length - 1].includes("/")) {
    const [start, interval] = params[params.length - 1].split("/");
    // daysInterval = parseInt(interval);
    if (start !== "*") {
      selectedDays.push(parseInt(start));
    } else if (start === "*") {
      selectedDays.push(parseInt(interval));
    }
  } else if (params[params.length - 1].includes("-")) {
    const [min, max] = params[params.length - 1].split("-");
    const selectedDaysList: string[] = getList(parseInt(min), parseInt(max));
    selectedDaysList.forEach((day) => selectedDays.push(parseInt(day)));
  } else {
    selectedDays.push(parseInt(params[params.length - 1]));
  }
  return {
    selectedMonths: selectedMonths.filter((a) => a !== -1),
    selectedDays: selectedDays.filter((a) => a !== -1),
    selectedDaysOfTheMonth: selectedDaysOfTheMonth.filter((a) => a !== -1),
    selectedHours: selectedHours.filter((a) => a !== -1),
    selectedMinutes: selectedMinutes.filter((a) => a !== -1),
  };
};
