import {
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography,
} from "@mui/material";
import { getTranslation } from "common";
import { useSnackbar, useTranslations } from "hooks";
import React, { useCallback, useEffect, useState } from "react";
import { Add, Clear } from "@mui/icons-material";
import { UsersMenu } from "./menus";
import { User } from "./UserItem";
import { groupsApi } from "api";
import AvatarRem from "./AvatarRem";


interface GroupSelectedPanelProps {
    groupId: number | null;
    organizationId: number;
    inMobile?: boolean;
    administrator: boolean;
}

const GroupSelectedPanel: React.FC<GroupSelectedPanelProps> = ({
    groupId,
    organizationId,
    inMobile,
    administrator,
}) => {
    const translations = useTranslations();
    const [userMenu, setUserMenu] = useState<null | HTMLElement>(null);
    const { sendSnack } = useSnackbar();
    const [associatedUsers, setAssociatedUsers] = useState<User[]>([]);

    const refreshUsers = useCallback(async () => {
        const { data } = await groupsApi.get(`/${groupId}/users`);
        setAssociatedUsers(data.users);
    }, [groupId]);

    useEffect(() => {
        refreshUsers();
    }, [refreshUsers]);

    const linkUser = async (id: number) => {
        /* /groups/($id)/users/($idutente) POST/DELETE associa/dissocia */
        try {
            const createRes = await groupsApi.post(`${groupId}/users/${id}`);
            refreshUsers();
            sendSnack({
                message: `${createRes.data.responsemessage}`,
                type: "success",
            });
            setUserMenu(null);
        } catch {
        } finally {
        }
    };

    const unlinkUser = async (id: number) => {
        /* /groups/($id)/users/($idutente) POST/DELETE associa/dissocia */
        try {
            const deleteApi = await groupsApi.delete(`${groupId}/users/${id}`);
            refreshUsers();
            sendSnack({
                message: `${deleteApi.data.responsemessage}`,
                type: "success",
            });
            setUserMenu(null);
        } catch {
        } finally {
        }
    };

    return (
        <React.Fragment>
            <UsersMenu
                anchor={userMenu}
                associated={associatedUsers}
                onClose={() => setUserMenu(null)}
                onLink={linkUser}
                organizationId={organizationId}
            />
            {inMobile || <Divider flexItem orientation="vertical" sx={{ m: 1 }} />}

            <Stack maxWidth={300} height="100%" spacing={1} flex={1}>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    justifyContent="space-between"
                >
                    <Typography variant="subtitle1">
                        {getTranslation(translations, "users.name")}
                    </Typography>
                    {administrator && (
                        <IconButton
                            id="add-users"
                            onClick={({ currentTarget }) => setUserMenu(currentTarget)}
                            size="medium"
                            color="info"
                        >
                            <Add fontSize="medium" />
                        </IconButton>
                    )}
                </Stack>
                <List
                    id="associated-users"
                    sx={{ height: "100%", overflow: "auto" }}
                    disablePadding
                >
                    {/* {loading && (
                <Stack alignItems="center" justifyContent="center">
                <CircularProgress />
                </Stack>
              )} */}
                    {associatedUsers.map(({ firstname, lastname, id, picture }) => (
                        <ListItem alignItems="center" key={`associated-users-${id}`}>
                            <ListItemAvatar sx={{ height: "40px", width: "40px" }}>
                                <AvatarRem icon={picture || ""}>
                                    {firstname[0]}
                                    {lastname[0]}
                                </AvatarRem>
                            </ListItemAvatar>
                            <ListItemText>
                                {firstname} {lastname}
                            </ListItemText>
                            <ListItemSecondaryAction>
                                {administrator && (
                                    <IconButton
                                        id={`remove-user-${id}`}
                                        size="medium"
                                        color="error"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            unlinkUser(id);
                                        }}
                                    >
                                        <Clear fontSize="medium" />
                                    </IconButton>
                                )}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </Stack>
        </React.Fragment>
    );
};

export default GroupSelectedPanel;
