import React from "react";
import { ListItem } from "@mui/material";
import TaskCard from "./TaskCard";
import { Draggable } from "react-beautiful-dnd";
import { getStyle } from "common/utilities";
import { TaskCardProps } from "model/activitiesModel";

interface DraggableTaskCardProps extends TaskCardProps {
  index: number;
  lastElementRef?: (node: any) => void;
}
const DraggableTaskCard: React.FC<DraggableTaskCardProps> = ({
  index,
  lastElementRef,
  ...props
}) => {
  return (
    <Draggable draggableId={`${props.task.idprocesstoken}`} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={(node) => {
            provided.innerRef(node);
            lastElementRef && lastElementRef(node);
          }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          id={`${props.task.state === 1 ? "doing-task" : "todo-task"}-${
            props.task.idprocesstoken
          }`}
          sx={{
            width: "calc(100% - 4px)",
            display: "flex",
          }}
          style={{paddingTop: 0, ...getStyle(
            provided.draggableProps.style,
            snapshot,
            props.task.state === 1,
          )}}
        >
          <TaskCard {...props} />
        </ListItem>
      )}
    </Draggable>
  );
};
export default DraggableTaskCard;
