import {
  Brush,
  Description,
  Folder,
  History,
  InsertDriveFileOutlined,
  Slideshow,
  TableChart,
} from "@mui/icons-material";
import {
  Avatar,
  CircularProgress,
  Collapse,
  FormHelperText,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Tooltip,
} from "@mui/material";

import { processesUploadsApi, uploadsApi } from "api";
import { useSnackbar, useTranslations } from "hooks";
import React, { useState } from "react";
import { getTranslation } from "common";
import {
  SUPPORTED_EXTENSIONS,
  formatDate,
  getFileExtension,
  toBase64,
} from "common/utilities";

export interface Attachment {
  id: number;
  idorganization: number;
  filename: string;
  description: string;
  idtype: number;
  cloudlink: string;
  creation: string;
  last_modified: string | null;
  foldername: string;
}
interface AttchmentListProps {
  attachments: Attachment[];
  // fatherWidth: number;
  canManage?: boolean;
  knownWidth: number;
  horizontal?: boolean;
  getAttachments: () => Promise<void>;
  images: { [key: string]: string };
  histories: { [key: string]: any };
}
const AttachmentList: React.FC<AttchmentListProps> = ({
  attachments,
  // fatherWidth,
  knownWidth,
  horizontal,
  getAttachments,
  images,
  histories,
  canManage = false,
}) => {
  const download = async (attachment: Attachment) => {
    setLoading(true);
    try {
      const { data } = await processesUploadsApi.get(`/${attachment.id}`);
      const a = document.createElement("a");
      a.href = data.blob;
      a.download = data.filename;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
    } catch {
    } finally {
      setLoading(false);
    }
  };

  let organizationId: number | null | undefined = null;
  const { sendSnack } = useSnackbar();
  const translations = useTranslations();
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleDeleteFile = async (attachment: Attachment) => {
    // delete /processesuploads/($id)
    // const { data } = await processesUploadsApi.delete(`/${attachment.id}`);
    try {
      const deleteApi = await processesUploadsApi.delete(`/${attachment.id}`);
      sendSnack({
        message: `${deleteApi.data.responsemessage}`,
        type: "success",
      });
      getAttachments();
    } catch {
    } finally {
    }
  };

  const handleUpdateFile = async (
    attachment: Attachment,
    files: FileList | null
  ) => {
    setLoading(true);
    try {
      if (files && files.length > 0) {
        const file = files[0];
        const payload = {
          idorganization: organizationId,
          filename: file.name,
          cloudlink: "",
          idtype: 1,
          description: file.name,
          blob: await toBase64(file),
        };
        setLoading(true);
        try {
          const updateApi = await uploadsApi.put(`/${attachment.id}`, payload);
          sendSnack({
            message: `${updateApi.data.responsemessage}`,
            type: "success",
          });
        } catch {
        } finally {
          setLoading(false);
        }
        getAttachments();
      }
    } finally {
    }
  };

  const isFromGoogleDrive = (idtype: number) => {
    return [20, 21, 22, 23, 24, 25].includes(idtype);
  };

  return horizontal ? (
    <Stack direction="row" spacing={1} flex={1}>
      <Stack
        direction="row"
        position="relative"
        sx={{
          overflowX: "auto",
          // maxWidth: `calc(${fatherWidth}px - ${knownWidth}px)`,
        }}
        width="100%"
      >
        {attachments
          .sort(
            (a, b) =>
              new Date(b.creation).getTime() - new Date(a.creation).getTime()
          )
          .map((attachment) => (
            <Tooltip
              key={`horizontal-attachment-${attachment.id}`}
              title={attachment.filename}
            >
              <Stack maxWidth={50} direction="column" alignItems="center">
                {/* <IconButton onClick={() => download(attachment)} size="small">
                  <InsertDriveFileOutlined />
                </IconButton> */}
                <FormHelperText
                  id="attachment-list"
                  sx={{
                    textOverflow: "ellipsis",
                    maxWidth: 50,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {attachment.filename}
                </FormHelperText>
              </Stack>
            </Tooltip>
          ))}
      </Stack>
    </Stack>
  ) : (
    <Stack direction="column" spacing={1} flex={1} width="100%">
      <Stack
        component={List}
        direction="column"
        position="relative"
        width="100%"
      >
        {attachments
          .sort(
            (a, b) =>
              new Date(b.creation).getTime() - new Date(a.creation).getTime()
          )
          .map((attachment) => (
            <React.Fragment key={`vertical-attachment-${attachment.id}`}>
              <ListItem id={`attachment-${attachment.id}`} dense>
                <ListItemAvatar>
                  <Avatar
                    sx={{ fontSize: "0.8rem" }}
                    alt="..."
                    src={
                      SUPPORTED_EXTENSIONS.includes(
                        getFileExtension(attachment.filename) || ""
                      )
                        ? images[attachment.id]
                        : undefined
                    }
                  //variant = "square"
                  //folder = 21 / document = 22 / spreadsheet = 23 / presentation = 24 / drawing = 25 / default = 20
                  //sono da rivedere l' icona per presentation (sembra più un video)
                  //per l'excel è abbastanza decente
                  >
                    {attachment.idtype === 21 ? (
                      <Folder />
                    ) : attachment.idtype === 22 ? (
                      <Description />
                    ) : attachment.idtype === 23 ? (
                      <TableChart />
                    ) : attachment.idtype === 24 ? (
                      <Slideshow />
                    ) : attachment.idtype === 25 ? (
                      <Brush />
                    ) : (
                      <InsertDriveFileOutlined />
                    )}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{
                    textOverflow: "ellipsis",
                    maxWidth: "auto",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                  primary={attachment.foldername || attachment.filename}
                  secondary={
                    <Stack direction="row" spacing={1} component="span">
                      {canManage && (
                        <Link
                          id={`delete-attachment-${attachment.id}`}
                          href="#"
                          variant="body2"
                          color="inherit"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleDeleteFile(attachment);
                          }}
                        >
                          {getTranslation(
                            translations,
                            "settings.delete.label"
                          )}
                        </Link>
                      )}
                      {isFromGoogleDrive(attachment.idtype) ? ( //credo di aver fatto qualcosa di accettabile
                        <Link
                          id={`open-attachment-${attachment.id}`}
                          href={attachment.cloudlink}
                          variant="body2"
                          color="inherit"
                          target="_blank"
                        >
                          {getTranslation(translations, "attachment.view.ondrive")}
                        </Link>
                      ) : (
                        <>
                          {canManage && !attachment.cloudlink && (
                            <label>
                              <Link
                                id={`modify-attachment-${attachment.id}`}
                                sx={{ cursor: "pointer" }}
                                variant="body2"
                                color="inherit"
                              >
                                {getTranslation(
                                  translations,
                                  "settings.modify.label"
                                )}
                              </Link>
                              <input
                                id={`modify-attachment-${attachment.id}-input`}
                                type="file"
                                hidden
                                onChange={({ target }) => {
                                  handleUpdateFile(
                                    attachment,
                                    target.files
                                  );
                                }}
                              />
                            </label>
                          )}
                          <Link
                            id={`download-attachment-${attachment.id}`}
                            href="#"
                            variant="body2"
                            color="inherit"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              download(attachment);
                            }}
                          >
                            {getTranslation(
                              translations,
                              "settings.download.label"
                            )}
                          </Link>
                        </>
                      )}
                    </Stack>
                  }
                />
                {histories[attachment.id].length > 0 && (
                  <ListItemSecondaryAction>
                    <IconButton
                      id="history-button"
                      onClick={() => {
                        setSelectedId(
                          selectedId === attachment.id ? null : attachment.id
                        );
                      }}
                    >
                      <History />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
              {histories[attachment.id].length > 0 && (
                <Collapse in={selectedId === attachment.id}>
                  <List sx={{ width: "100%", pl: 4 }} dense>
                    {histories[attachment.id].map(
                      (history: any, index: any) => (
                        <ListItem
                          id={`attachment-history-${history.id}`}
                          key={index}
                        >
                          <ListItemText
                            sx={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                            primary={`${getTranslation(
                              translations,
                              "settings.filename.label"
                            )}
                            : ${history.filename}`}
                            secondary={`${getTranslation(
                              translations,
                              "settings.creation.label"
                            )}
                            : ${history.nameuser}, ${formatDate(
                              history.creation
                            )}`}
                          ></ListItemText>
                          <ListItemSecondaryAction>
                            <Link
                              id={`download-history-${history.id}`}
                              href="#"
                              variant="body2"
                              color="inherit"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                download(history);
                              }}
                            >
                              {getTranslation(
                                translations,
                                "settings.download.label"
                              )}
                              ;
                            </Link>
                          </ListItemSecondaryAction>
                        </ListItem>
                      )
                    )}
                  </List>
                </Collapse>
              )}
              {loading && (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              )}
            </React.Fragment>
          ))}
      </Stack>
    </Stack>
  );
};
export default AttachmentList;
