import React from "react";
import { useNavigate } from "react-router-dom";
/*******************
 ****** REDUX ******
 *******************/
// import axios from "axios";
// import { useDispatch } from "react-redux";
/****************
 ****** UI ******
 ****************/
import { Box, Button, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Google from "image/Google.svg";
/********************
 ****** OAUTH2 ******
 ********************/
import {
  // GoogleLogin,
  // CredentialResponse,
  useGoogleLogin,
  // TokenResponse,
  CodeResponse,
} from "@react-oauth/google";
// import FacebookLogin from "react-facebook-login";
import { getTranslation } from "common";
import { useTranslations } from "hooks";
import { usersApi } from "api";
// import { Facebook } from "@mui/icons-material";
// import { AuthContext } from "contexts";
// import { usersApi } from "api";
// import jwtDecode from "jwt-decode";
interface OAuthProps {
  login?: boolean;
  onGoogleConfirm: (token: string, registrated: boolean) => Promise<void>;
  showLoginButton?: boolean; //per non far vedere "duplicati" del bottone login
}
const OAuth2: React.FC<OAuthProps> = ({ login = false, onGoogleConfirm, showLoginButton = true }) => { //quindi gli passiamo la nuova prop
  const navigate = useNavigate();
  const translations = useTranslations();
  // const dispatch = useDispatch();
  // const url: string | undefined = process.env.REACT_APP_BACKEND_BASE_URI;
  // const { actions: { setUser, setJwt }} = useContext(AuthContext);

  const failureGoogle: () => void = () => {
    navigate("/login");
  };

  const responseGoogle = async (
    response: Omit<CodeResponse, "error" | "error_description" | "error_uri">
  ) => {
    try {
      const { data } = await usersApi.post("oauth2", { ...response, redirect: window.origin });
      await onGoogleConfirm(data.token, data.registrated);
    } catch (e) {
    } finally {
    }
  };
  const googleLogin = useGoogleLogin({
    onSuccess: responseGoogle,
    onError: failureGoogle,
    flow: "auth-code",
  });

  // const responseFacebook = async (response: any) => {
  //   if (response.status === "unknown") {
  //     navigate("/login");
  //   } else {
  //     if (!login) {
  //       await axios
  //         .post(url + "users/oauth2", {
  //           email: response.email,
  //           name: response.name,
  //           surname: "",
  //           token: response.accessToken,
  //           subscriptiontype: 2,
  //         })
  //         .then((result) => {
  //           setUser({
  //             ...result.data,
  //             userId: result.data.id,
  //             email: response.email,
  //             firstname: response.name,
  //           })
  //           // dispatch(
  //           //   addUser({
  //           //   })
  //           // );
  //           navigate("/organization");
  //         });
  //     } else {
  //       setJwt(response.accessToken);
  //       setUser({
  //         ...response,
  //         email: response.email,
  //         firstname: response.name,
  //       });
  //       // dispatch(addJwt(response.accessToken));
  //       // dispatch(
  //       //   addUser({
  //       //     email: response.email,
  //       //     firstname: response.name,
  //       //   })
  //       // );
  //       navigate("/dashboard/organizations");
  //     }
  //   }
  // };

  return (
    <React.Fragment>
      <Divider />
      {/* <GoogleLogin
        // clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
        onSuccess={responseGoogle}
        onError={failureGoogle}
        // cookiePolicy="single_host_origin"
        // render={(renderProps: any) => (
          // )}
        /> */}
      {/* <Button
        id="google-login-btn"
        variant="outlined"
        color="inherit"
        onClick={() => googleLogin()}
        sx={{ backgroundColor: (_) => _.palette.common.white, height: 37 }}
      >
        <Box
          component="img"
          src={Google}
          alt="Google icon"
          width="1.5rem"
          display="inline"
          mr={2}
        />
        <Typography sx={{
          fontWeight: "bold", fontSize: {
            xs: '12px',
          }
        }} color="black">
          {getTranslation(
            translations,
            "register.label.button_continue_with_gogole"
          )}
        </Typography>
      </Button> */}
      {/* <FacebookLogin
        appId="3237249853265283"
        fields="name,email"
        scope="public_profile,user_friends"
        callback={responseFacebook}
        icon={<Facebook sx={{ mr: 2 }} />}
        buttonStyle={{
          color: "#fff",
          backgroundColor: "#1877f2",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
          border: "1px solid transparent",
          padding: "0.375rem 0.75rem",
          width: "100%",
          textTransform: "capitalize",
          borderRadius: "0.25rem",
        }}
        textButton={getTranslation(
          translations,
          "register.label.button_continue_with_fb"
        )}
      /> */}
      <Divider />
      <React.Fragment>
        {login ? (
          <React.Fragment>
            <Button
              component={Link}
              to="/registration"
              variant="text"
              color="inherit"
            >
              {getTranslation(
                translations,
                "register.label.register_an_account"
              )}
            </Button>
            <Button
              id="forgot-password-btn"
              component={Link}
              to="/forgotpassword"
              variant="text"
              color="inherit"
            >
              {getTranslation(translations, "register.forgotpassword.label")}
            </Button>
          </React.Fragment>
        ) : (
          showLoginButton && (  //così riusciamo a gestire meglio la visualizzazione del testo, abbiamo l'esempio in email.tsx
            <Button component={Link} to="/login" variant="text" color="inherit">
              {getTranslation(
                translations,
                "register.label.ahref_login_have_account"
              )}
            </Button>
          )
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

export default OAuth2;
