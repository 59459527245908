export default class CustomPalette {
  constructor(bpmnFactory, create, elementFactory, palette, translate) {
    this.bpmnFactory = bpmnFactory;
    this.create = create;
    this.elementFactory = elementFactory;
    this.translate = translate;

    palette.registerProvider(this);
  }

  getPaletteEntries(element) {
    const { bpmnFactory, create, elementFactory, translate } = this;

    function createTimerIntermediateCatchEvent() {
      return function (event) {
        const businessObject = bpmnFactory.create(
          "bpmn:IntermediateCatchEvent"
        );

        const shape = elementFactory.createShape({
          type: "bpmn:IntermediateCatchEvent",
          eventDefinitionType: "bpmn:TimerEventDefinition",
          businessObject: businessObject,
        });
        create.start(event, shape);
      };
    }

    return function (entries) {
      delete entries["hand-tool"];
      delete entries["tool-separator"];
      delete entries["global-connect-tool"];
      delete entries["create.data-object"];
      delete entries["create.data-store"];
      delete entries["create.group"];
      delete entries["create.subprocess-expanded"];
      delete entries["create.intermediate-event"];

      entries["create.timer-intermediate-catch-event"] = {
        group: "timer",
        className: "bpmn-icon-intermediate-event-catch-timer",
        title: translate("Timer Intermediate Catch Event"),
        action: {
          dragstart: createTimerIntermediateCatchEvent(),
          click: createTimerIntermediateCatchEvent(),
        },
      };

      return entries;
    };
  }
}

CustomPalette.$inject = [
  "bpmnFactory",
  "create",
  "elementFactory",
  "palette",
  "translate",
];
