import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
  useOrganizations,
  useSnackbar,
  useTranslations,
  useUser,
  useVersions,
} from "hooks";
import {
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Tooltip,
  Badge,
} from "@mui/material";
import { getTranslation, toDataUrl, OutletType } from "common";
import SearchBar from "./SearchBar";
// import FastCreation from "./FastCreation";
import { useOutletContext } from "react-router-dom";
import { organizationsApi } from "api";
import { OrganizationDialog } from "./dialogs";
import { Diversity3, Edit, Star } from "@mui/icons-material";
import { Priority } from "hooks/usePriorities";
import { putPrioritiesOnServer } from "common/utilities";
import AvatarRem from "./AvatarRem";
import { removeSelectedOrganization } from "Redux/organizationReducer";
import { useDispatch } from "react-redux";

export interface Organization {
  creation?: string;
  icon?: string | null;
  id?: number | null;
  name?: string;
}
interface OrganizationsPanelProps {
  onSelect: Function;
  selectedId?: number | null;
}
type Ref = { getOrganizations: Function } | undefined;

const OrganizationsPanel = forwardRef<Ref, OrganizationsPanelProps>(
  ({ onSelect, selectedId }, ref) => {
    const [organizationEdit, setOrganizationEdit] =
      useState<Organization | null>(null);
    const translations = useTranslations();
    // const user = useUser();
    const { sendSnack } = useSnackbar();
    const { getVersion, updateVersion } = useVersions();
    const { firstFilter: searchText, setFirstFilter } =
      useOutletContext<OutletType>();
    const user = useUser();
    const [organizations, getOrganizations] = useOrganizations(1);
    const dispatch = useDispatch();
    useImperativeHandle(
      ref,
      () => ({
        getOrganizations,
      }),
      [getOrganizations]
    );

    const handleDeleteOrganization = async () => {
      if (organizationEdit) {
        try {
          const deleteApi = await organizationsApi.delete(
            `/${selectedId}/users/${user?.userId}`
          );
          sendSnack({
            message: `${deleteApi.data.responsemessage}`,
            type: "success",
          });
          dispatch(removeSelectedOrganization({}));
          setOrganizationEdit(null);

          // getFirstUsers();
        } catch {
        } finally {
          getOrganizations();
        }
      }
    };

    const handleSaveEdit = async (
      imageUrl: string | null,
      name: string | null,
      priorities: Priority[]
    ) => {
      try {
        let icon = null;
        if (imageUrl) {
          icon = await toDataUrl(imageUrl);
        }
        const updateApi = await organizationsApi.put(
          `${organizationEdit?.id}`,
          { name, icon }
        );
        updateVersion(organizationEdit?.id);
        setOrganizationEdit(null);
        if (organizationEdit?.id) {
          await putPrioritiesOnServer(priorities, organizationEdit.id);
        }
        sendSnack({
          message: `${updateApi.data.responsemessage}`,
          type: "success",
        });
        getOrganizations();
      } catch {
      } finally {
      }
    };

    return (
      <React.Fragment>
        <Stack sx={{ height: "100%" }} spacing={3}>
          <Stack spacing={2}>
            <SearchBar
              id="search-bar-org"
              placeholder={getTranslation(
                translations,
                "pm.organizationchart.ricerca.placeholder"
              )}
              setFilter={setFirstFilter}
            />
            {/* <FastCreation
              id="organizations"
              label={getTranslation(
                translations,
                "organizations.edit.suggestnew"
              )}
              onChange={createOrganization}
            /> */}
          </Stack>
          <Stack sx={{ height: "100%" }} overflow="auto">
            <Typography variant="subtitle1">
              {getTranslation(translations, "menu.left.organizations")}
            </Typography>
            <Divider flexItem sx={{ mb: 1 }} />
            <List id="organizations" sx={{ overflow: "auto" }} disablePadding>
              {(organizations || []).map(
                ({ icon, name, id, administrator, ...rest }) =>
                  name?.toLowerCase().includes(searchText.toLowerCase()) && (
                    <ListItemButton
                      id={`organization-${id}`}
                      key={`organization-${id}-${getVersion(id)}`}
                      selected={selectedId === id}
                      onClick={() => onSelect(selectedId === id ? null : id)}
                    >
                      <ListItemAvatar sx={{ height: "40px", width: "40px" }}>
                        <Badge
                          color="primary"
                          overlap="circular"
                          sx={{
                            "& .MuiBadge-badge": {
                              p: "0px !important",
                            },
                          }}
                          badgeContent={
                            administrator ? (
                              <Star sx={{ fontSize: "10px" }} />
                            ) : (
                              0
                            )
                          }
                        >
                          <AvatarRem icon={icon || ""}>
                            <Diversity3 />
                          </AvatarRem>
                        </Badge>
                      </ListItemAvatar>
                      <ListItemText>{name}</ListItemText>
                      {selectedId === id && administrator && (
                        <ListItemSecondaryAction>
                          <Tooltip
                            title={getTranslation(
                              translations,
                              "pm.button.edit"
                            )}
                          >
                            <IconButton
                              id={`btn-modify-org-${id}`}
                              size="medium"
                              color="secondary"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setOrganizationEdit({
                                  icon,
                                  name,
                                  id,
                                  ...rest,
                                });
                              }}
                            >
                              <Edit fontSize="medium" />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      )}
                    </ListItemButton>
                  )
              )}
            </List>
          </Stack>
        </Stack>
        <OrganizationDialog
          onSave={handleSaveEdit}
          onDelete={handleDeleteOrganization}
          onClose={() => setOrganizationEdit(null)}
          open={Boolean(organizationEdit)}
          organization={organizationEdit}
        />
      </React.Fragment>
    );
  }
);

export default OrganizationsPanel;
