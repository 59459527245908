import React, { useState } from "react";
import {
  Box,
  IconButton,
  Stack,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { getTranslation } from "common";
import { useTranslations } from "hooks";
import ProcessItem, { StartedProcess } from "./ProcessItem";
import AutoSizer from "react-virtualized-auto-sizer";
import { VirtualTable } from "./VirtualizedTable";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { SortCriterion } from "common/utilities";
import { SortState } from "hooks/useSortableList";
import { ConfirmDialog } from "./dialogs";
import { useSnackbar } from "hooks";
import { processesApi } from "api";
import { TaskDetail } from "./TaskItem";
import NoMonitor from "image/NoMonitor.png"

type RowProps = {
  index: number;
  style: Object;
};

interface ProcessesListProps {
  title: string;
  processes: Array<StartedProcess>;
  filters: Record<string, any>;
  refresh: () => void;
  handleChangeSort: (key: string) => void;
  newSort: SortCriterion;
  filteredUserDataKeys: string[];
}
const ProcessesList: React.FC<ProcessesListProps> = ({
  processes = [],
  title,
  filters,
  refresh,
  handleChangeSort,
  newSort,
  filteredUserDataKeys,
}) => {
  const translations = useTranslations();

  const [confirmData, setConfirmData] = useState<null | {
    task: TaskDetail;
    type: "delete" | "terminate";
  }>(null);

  const Row: React.FC<RowProps> = React.memo(({ index, style }) => {
    const process = processes[index];
    return (
      <ProcessItem
        handleClick={(task, type) => setConfirmData({ task, type })}
        refresh={refresh}
        key={process.idprocesstoken}
        filters={filters}
        process={process}
        userDataKeys={filteredUserDataKeys}
      />
    );
  });

  const { sendSnack } = useSnackbar();

  const handleActionConfirm = async () => {
    try {
      if (confirmData?.type === "terminate") {
        const { data } = await processesApi.post(
          `/${confirmData?.task.idprocess}/terminate`
        );
        sendSnack({
          message: data.responsemessage,
          type: "success",
        });
      } else if (confirmData?.type === "delete") {
        const { data } = await processesApi.delete(
          `/${confirmData?.task.idprocess}`
        );
        sendSnack({
          message: data.responsemessage,
          type: "success",
        });
      }
      refresh();
    } catch (error) {
    } finally {
      setConfirmData(null);
    }
  };

  return (
    <React.Fragment>
      <ConfirmDialog
        open={confirmData?.type === "terminate"}
        onClose={() => setConfirmData(null)}
        onConfirm={handleActionConfirm}
        cancelText={getTranslation(translations, "generic.button.cancel")}
        confirmText={getTranslation(translations, "generic.button.confirm")}
        title={getTranslation(translations, "generic.button.confirm")}
        content={getTranslation(translations, "general.terminate.text")}
      />
      <ConfirmDialog
        open={confirmData?.type === "delete"}
        onClose={() => setConfirmData(null)}
        onConfirm={handleActionConfirm}
        cancelText={getTranslation(translations, "generic.button.cancel")}
        confirmText={getTranslation(translations, "generic.button.confirm")}
        title={getTranslation(translations, "generic.button.confirm")}
        content={getTranslation(translations, "general.delete.text")}
      />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6">{title}</Typography>
      </Stack>
      <div
        style={{
          height: `calc(100% - 120px)`,
          maxHeight: `calc(100% - 120px)`,
        }}
      >
        {processes.length === 0 ? (
         <Box
         height={450}
         position="relative"
         display="flex"
         flexDirection="column"
         justifyContent="center"
         alignItems="center"
       >
         <Box
           component="img"
           src={NoMonitor}
           alt="No processes"
           sx={{
             maxWidth: '100%',
             maxHeight: '100%',
             width: 'auto',
             height: 'auto',
             objectFit: 'contain'
           }}
         />
         <Typography 
           variant="caption" 
           sx={{ 
             position: 'absolute',
             bottom: '5%',
             left: '50%',
             transform: 'translateX(-50%)',
             textAlign: 'center'
           }}
         >
           {getTranslation(translations, "generic.nomonitor.message")}
         </Typography>
       </Box>
        ) : (
          <AutoSizer style={{ width: "100%" }}>
            {({
              height,
            }: {
              height: number;
              width: number;
              scaledWidth: number;
              scaledHeight: number;
            }) => (
              <React.Fragment>
                <VirtualTable
                  height={height}
                  width="100%"
                  itemCount={processes.length}
                  itemSize={130}
                  header={
                    <TableHead sx={{ "& th": { fontWeight: "bold" } }}>
                      <TableRow>
                        <TableCell
                          sx={{ width: "15%", border: "2px solid #ddd" }}
                        >
                          <Stack
                            flexDirection="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography style={{ fontWeight: "bold" }}>
                              {getTranslation(translations, "generic.process")}
                            </Typography>
                            <IconButton
                              sx={{
                                opacity: newSort.model ? 1 : 0,
                                mt: "0px !important",
                                "&:hover": { opacity: 1 },
                              }}
                              size="small"
                              onClick={() => handleChangeSort("model")}
                            >
                              {newSort.model === SortState.Asc ? (
                                <ArrowUpward fontSize="small" />
                              ) : (
                                <ArrowDownward fontSize="small" />
                              )}
                            </IconButton>
                          </Stack>
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "15%",
                            border: "2px solid #ddd",
                            borderLeft: "none",
                          }}
                        >
                          <Stack
                            flexDirection="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography style={{ fontWeight: "bold" }}>
                              {getTranslation(
                                translations,
                                "generic.model_name.caption"
                              )}
                            </Typography>
                            <IconButton
                              sx={{
                                opacity: newSort.processmodelname ? 1 : 0,
                                mt: "0px !important",
                                "&:hover": { opacity: 1 },
                              }}
                              size="small"
                              onClick={() => handleChangeSort("processmodelname")}
                            >
                              {newSort.processmodelname === SortState.Asc ? (
                                <ArrowUpward fontSize="small" />
                              ) : (
                                <ArrowDownward fontSize="small" />
                              )}
                            </IconButton>
                          </Stack>
                        </TableCell>
                        {filteredUserDataKeys.map((key) => (
                          <TableCell
                            key={key}
                            sx={{ border: "2px solid #ddd", borderLeft: "none" }}
                          >
                            <Stack
                              flexDirection="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Tooltip title={key}>
                                <Typography
                                  sx={{
                                    maxWidth: 150,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                  fontWeight="bold"
                                >
                                  {key}
                                </Typography>
                              </Tooltip>
                              <IconButton
                                sx={{
                                  opacity: newSort[key] ? 1 : 0,
                                  mt: "0px !important",
                                  "&:hover": { opacity: 1 },
                                }}
                                size="small"
                                onClick={() => handleChangeSort(key)}
                              >
                                {newSort[key] === SortState.Asc ? (
                                  <ArrowUpward fontSize="small" />
                                ) : (
                                  <ArrowDownward fontSize="small" />
                                )}
                              </IconButton>
                            </Stack>
                          </TableCell>
                        ))}
                        <TableCell
                          sx={{
                            width: "15%",
                            border: "2px solid #ddd",
                            borderLeft: "none",
                          }}
                        >
                          <Stack
                            flexDirection="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography style={{ fontWeight: "bold" }}>
                              {getTranslation(translations, "generic.task")}
                            </Typography>
                            <IconButton
                              sx={{
                                opacity: newSort.activity ? 1 : 0,
                                mt: "0px !important",
                                "&:hover": { opacity: 1 },
                              }}
                              size="small"
                              onClick={() => handleChangeSort("activity")}
                            >
                              {newSort.activity === SortState.Asc ? (
                                <ArrowUpward fontSize="small" />
                              ) : (
                                <ArrowDownward fontSize="small" />
                              )}
                            </IconButton>
                          </Stack>
                        </TableCell>
                        {filters.status === "current" && (
                          <TableCell
                            sx={{
                              width: "10%",
                              border: "2px solid #ddd",
                              borderLeft: "none",
                            }}
                          >
                            {getTranslation(
                              translations,
                              "pm.label.process_status"
                            )}
                          </TableCell>
                        )}
                        <TableCell
                          sx={{
                            width: "20%",
                            border: "2px solid #ddd",
                            borderLeft: "none",
                          }}
                        >
                          {getTranslation(
                            translations,
                            "pm.label.process_participant"
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "5%",
                            border: "2px solid #ddd",
                            borderLeft: "none",
                          }}
                        >
                          {getTranslation(translations, "generic.action.title")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  }
                  row={Row}
                />
              </React.Fragment>
            )}
          </AutoSizer>)}
      </div>
    </React.Fragment>
  );
};

export default ProcessesList;
