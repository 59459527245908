import { Box, Divider, Stack, Typography } from "@mui/material";
import DraggableTaskCard from "./DraggableTaskCard";
import {
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  Droppable,
} from "react-beautiful-dnd";
import { Task } from "model/activitiesModel";
import { getTranslation } from "../common";
import React from "react";
import { useTranslations } from "../hooks";
import noTaskTodo from "../image/img_todo.svg";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import TaskCard from "./TaskCard";
import { LABEL_OFFSET, TASK_CARD_HEIGHT } from "common/utilities";

// definisce le props che il componente riceve
interface ToDoPanelProps {
  userId: number;
  labelCompleteMode: boolean;
  dragItem: Task | null;
  all: boolean;
  switchChecked: boolean;
  administrator?: boolean;
  goToDoing: (task: Task) => Promise<void>;
  setDetail: (idprocesstoken: number) => void;
  processedTasks: Task[];
}
type RowProps = {
  index: number;
  style: Object;
};
const HEADER_HEIGHT = 65;
const ToDoPanel: React.FC<ToDoPanelProps> = ({
  all,
  labelCompleteMode,
  switchChecked,
  dragItem,
  administrator,
  goToDoing,
  setDetail,
  processedTasks,
}) => {
  const translations = useTranslations();

  // Row rappresenta una singola task all'interno di una lista
  // react.memo evita re-render non necessari se le prop non cambiano
  const Row: React.FC<RowProps> = React.memo(({ index, style }) => {
    const task = processedTasks[index];
    return (
      <span style={style}>
        <DraggableTaskCard
          labelCompleteMode={labelCompleteMode}
          index={index}
          key={task.idprocesstoken}
          switchChecked={switchChecked}
          all={all}
          task={task}
          handleNextStep={goToDoing}
          setDetail={(idprocesstoken) => setDetail(idprocesstoken)}
          administrator={administrator}
        />
      </span>
    );
  });

  return (
    <Stack
      sx={{
        flex: 1,
        maxHeight: `100%`,
        backgroundColor: (_) => _.palette.grey[200],
        borderRadius: "15px",
      }}
    >
      <Box sx={{ height: HEADER_HEIGHT }}>
        <Typography sx={{ flex: 1, textAlign: "start" }} variant="h6">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ml: "10px",
              mt: "8px",
            }}
          >
            <div
              style={{
                height: "10px",
                width: "10px",
                borderRadius: "50%",
                backgroundColor: "#54BDC1",
                marginRight: "8px",
              }}
            />
            {`${getTranslation(
              translations,
              "pm.label.process_status.todo"
            )} (${processedTasks.length})`}
          </Box>
        </Typography>
        <Divider
          sx={{
            height: "3px",
            borderRadius: "10px",
            backgroundColor: (_) => _.palette.primary.main,
            mt: 3,
            mb: 3,
            width: "85%",
            mx: "auto",
          }}
        />
      </Box>
      <Droppable
        isDropDisabled={dragItem?.state === 0}
        droppableId="todo"
        mode="virtual"
        renderClone={(
          provided: DraggableProvided,
          snapshot: DraggableStateSnapshot,
          rubric: DraggableRubric
        ) => (
          <span {...provided.draggableProps} {...provided.dragHandleProps}>
            <TaskCard
              labelCompleteMode={labelCompleteMode}
              switchChecked={false}
              setDetail={(idprocesstoken) => setDetail(idprocesstoken)}
              task={dragItem || processedTasks[rubric.source.index]}
            />
          </span>
        )}
      >
        {(provided, snapshot) => (
          <Box
            sx={{
              maxHeight: `calc(100% - ${HEADER_HEIGHT}px})`,
              height: `calc(100% - ${HEADER_HEIGHT}px)`,
              flex: 1,
              display: "flex",
              flexDirection: "column",
              pb: 2,
            }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {processedTasks.length <= 0 && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <Box
                  component="img"
                  alt="..."
                  sx={{
                    width: "208px",
                    height: "208px",
                  }}
                  src={noTaskTodo}
                />
                <Typography color="textSecondary" component="p" sx={{ mt: 1 }}>
                  {getTranslation(
                    translations,
                    "generic.no_tasks_to_do.caption"
                  )}
                </Typography>
              </Box>
            )}
            <AutoSizer style={{ height: `calc(100% - ${HEADER_HEIGHT}px)` }}>
              {({
                height,
                width,
              }: {
                height: number;
                width: number;
                scaledWidth: number;
                scaledHeight: number;
              }) => (
                <List
                  height={height}
                  itemCount={processedTasks.length}
                  itemSize={TASK_CARD_HEIGHT + 10 + (labelCompleteMode ? LABEL_OFFSET : 0)}
                  width={width}
                  overscanCount={5}
                >
                  {Row}
                </List>
              )}
            </AutoSizer>
          </Box>
        )}
      </Droppable>
    </Stack>
  );
};

export default ToDoPanel;
