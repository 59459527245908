import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Zoom,
} from "@mui/material";
import React, { useState } from "react";
import { createPortal } from "react-dom";
import DialogHeader from "./dialogs/DialogHeader";
import { getTranslation } from "common";
import { useTranslations } from "hooks";
import { Notifications, Refresh } from "@mui/icons-material";

interface RefreshApplicationProps {
  newestVersion: string;
}

const RefreshApplication: React.FC<RefreshApplicationProps> = ({
  newestVersion,
}) => {
  const [open, setOpen] = useState(false);
  const translations = useTranslations();
  const toRefresh = process.env.REACT_APP_VERSION !== newestVersion;
  return (
    <React.Fragment>
      {createPortal(
        <Dialog open={open} onClose={() => setOpen(false)}>
          {/* <DialogHeader onClose={() => setOpen(false)}>
            {getTranslation(translations, "")}
          </DialogHeader> */}
          <DialogContent>
            <DialogContentText>
              {getTranslation(translations, "versioning.update.message")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => window.location.reload()} variant="text" size="small" startIcon={<Refresh />}>
              {getTranslation(translations, "versioning.update.button")}
            </Button>
          </DialogActions>
        </Dialog>,
        document.body
      )}
      <Zoom in={toRefresh}>
        <IconButton disabled={!toRefresh} onClick={() => setOpen(true)}>
          <Badge badgeContent={1} color="primary">
            <Notifications />
          </Badge>
        </IconButton>
      </Zoom>
    </React.Fragment>
  );
};

export default RefreshApplication;
