/* eslint-disable react-hooks/exhaustive-deps */
import {
  Add,
  Cancel,
  Delete,
  ExitToApp,
  Info,
  Save,
  Warning,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { ImagePicker } from "components";
import React, { useEffect, useState } from "react";
import { useForm, usePriorities } from "hooks";
import { Organization } from "../OrganizationsPanel";
import DialogHeader from "./DialogHeader";
import { useBreakpoint, useTranslations } from "hooks";
import { create_UUID, getTranslation } from "common";
import { Validation, isRequired } from "hooks/useForm";
import { Priority } from "hooks/usePriorities";
import { LoadingButton } from "@mui/lab";
interface OrganizationDialogProps {
  open: boolean;
  organization: Organization | null;
  onClose: () => void;
  onDelete?: () => void;
  onSave: (
    photo: string | null,
    name: string | null,
    priorities: Priority[]
  ) => Promise<void>;
}

const OrganizationDialog: React.FC<OrganizationDialogProps> = ({
  open,
  organization,
  onClose,
  onSave,
  onDelete,
}) => {
  const translations = useTranslations();
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [exitFromOrganization, setExitFromOrganization] =
    useState<boolean>(false);
  const validations: Validation[] = [
    ({ name }) =>
      isRequired(name) || {
        name: getTranslation(translations, "placeholders.nameisrequired.label"),
      },
  ];
  const [priorities, prioritiesLoading] = usePriorities(
    organization?.id || null
  );

  const isMobile = useBreakpoint("down", "sm");
  const { reset, isValid, changeHandler, values, errors, touched, setValues } =
    useForm(
      {
        priorities: [],
        name: organization?.name || "",
        icon: organization?.icon || "",
      },
      validations
    );

  useEffect(() => {
    if (!prioritiesLoading && priorities.length >= 0) {
      setValues((_: any) => ({ ..._, priorities }));
    }
  }, [prioritiesLoading, priorities]);

  useEffect(() => {
    if (!open) {
      reset();
      setExitFromOrganization(false);
    }
  }, [open]);

  useEffect(() => {
    if (organization) {
      setValues(organization);
    }
  }, [organization]);

  return (
    <Dialog fullScreen={isMobile} open={open} onClose={() => onClose()}>
      <DialogHeader onClose={onClose}>
        {organization?.id
          ? organization.name
          : getTranslation(translations, "organizations.edit.suggestnew")}
      </DialogHeader>
      <DialogContent>
        <Stack direction="row" sx={{ pt: (_) => _.spacing(0.7) }} spacing={2}>
          <Box flex={1}>
            <ImagePicker
              photoUrl={values?.icon || ""}
              relativePath={organization?.icon || ""}
              setPhotoUrl={(value) => changeHandler("icon", value)}
            />
          </Box>
          <Grid component="form" flex={2} spacing={2} container>
            <Grid item xs={12}>
              <TextField
                id="organizations-dialog"
                variant="outlined"
                fullWidth
                size="small"
                label={getTranslation(
                  translations,
                  "placeholders.organization.name.label"
                )}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                value={values?.name || ""}
                onChange={({ target }) => changeHandler("name", target.value)}
              />
            </Grid>
            <Grid container item xs={12}>
              <TableContainer
                component="fieldset"
                sx={{
                  borderRadius: (_) => _.shape.borderRadius / 4,
                  borderColor: (_) =>
                    _.palette.mode === "dark"
                      ? "#606060!important"
                      : "#c4c4c4!important",
                  borderWidth: "1px",
                }}
              >
                <FormLabel component="legend">
                  {getTranslation(translations, "generic.priorities.caption")}
                </FormLabel>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {getTranslation(
                          translations,
                          "register.registration.name.label"
                        )}
                      </TableCell>
                      <TableCell>
                        {getTranslation(translations, "generic.order.caption")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!prioritiesLoading &&
                      values &&
                      values.priorities &&
                      values.priorities
                        .filter((_: Priority) => !_.deleted)
                        .map(({ idorder, name, id, candelete }: Priority) => (
                          <TableRow key={`priority-${id}`}>
                            <TableCell>
                              <TextField
                              id={`priorities-name-${id}`}
                                variant="standard"
                                size="small"
                                value={name}
                                onChange={({ target }) => {
                                  const idxToEdit = values.priorities.findIndex(
                                    (__: Priority) => __.id === id
                                  );
                                  const copyCurrentState = [
                                    ...values.priorities,
                                  ];
                                  copyCurrentState[idxToEdit].name =
                                    target.value;
                                  changeHandler("priorities", copyCurrentState);
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                              id={`priorities-order-${id}`}
                                variant="standard"
                                size="small"
                                value={idorder}
                                type="number"
                                inputProps={{
                                  min: 0,
                                }}
                                onChange={({ target }) => {
                                  const idxToEdit = values.priorities.findIndex(
                                    (__: Priority) => __.id === id
                                  );
                                  const copyCurrentState = [
                                    ...values.priorities,
                                  ];
                                  copyCurrentState[idxToEdit].idorder = Number(
                                    target.value
                                  );
                                  changeHandler("priorities", copyCurrentState);
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <Tooltip
                                title={
                                  !candelete &&
                                  getTranslation(
                                    translations,
                                    "generic.cannot_delete_priority_assigned_to_activity.caption"
                                  )
                                }
                              >
                                <span>
                                  <IconButton
                                    disabled={!candelete}
                                    onClick={() => {
                                      const idxToDelete =
                                        values.priorities.findIndex(
                                          (__: Priority) => __.id === id
                                        );
                                      const copyCurrentState = [
                                        ...values.priorities,
                                      ];
                                      copyCurrentState[idxToDelete].deleted =
                                        true;
                                      changeHandler(
                                        "priorities",
                                        copyCurrentState
                                      );
                                    }}
                                    size="small"
                                  >
                                    {!candelete ? <Info /> : <Delete />}
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Stack justifyContent="center" alignItems="center">
                          <Button
                          id="add-priorities"
                            onClick={() => {
                              changeHandler("priorities", [
                                ...values.priorities,
                                {
                                  id: create_UUID(),
                                  new: true,
                                  name: "",
                                  idorder: 0,
                                  candelete: true,
                                  idorganization: organization?.id || null,
                                },
                              ]);
                            }}
                            startIcon={<Add />}
                            size="small"
                            variant="contained"
                          >
                            {getTranslation(
                              translations,
                              "generic.add.caption"
                            )}
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={2}
        >
          {onDelete && (
            <Tooltip
              title={getTranslation(
                translations,
                "generic.exit_organization.caption"
              )}
            >
              <Button
                id="btn-cancel-organization"
                variant="contained"
                color={exitFromOrganization ? "error" : "primary"}
                startIcon={exitFromOrganization ? <Warning /> : <ExitToApp />}
                size="small"
                onClick={() =>
                  exitFromOrganization
                    ? onDelete()
                    : setExitFromOrganization(true)
                }
              >
                {getTranslation(
                  translations,
                  exitFromOrganization
                    ? "generic.exit_organization.caption"
                    : "generic.exit"
                )}
              </Button>
            </Tooltip>
          )}
          <Button
            variant="contained"
            // color="warning"
            startIcon={<Cancel />}
            size="small"
            onClick={() => onClose()}
          >
            {getTranslation(translations, "user.buttoncancel.label")}
          </Button>
          <LoadingButton
            id="btn-save-organization"
            variant="contained"
            // color="success"
            loading={saveLoading}
            loadingPosition="start"
            disabled={!isValid}
            startIcon={<Save />}
            type="submit"
            size="small"
            onClick={async (e) => {
              e.preventDefault();
              setSaveLoading(true);
              await onSave(values.icon, values.name, values.priorities);
              setSaveLoading(false);
            }}
          >
            {getTranslation(translations, "pm.button.save")}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default OrganizationDialog;
