import {
  Add,
  Delete,
  Edit,
  Help,
  PrecisionManufacturing,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { robotsApi } from "api";
import { getTranslation } from "common";
import AvatarRem from "components/AvatarRem";
import { RobotDialog } from "components/dialogs";
import DialogHeader from "components/dialogs/DialogHeader";
import { useRobots, useSnackbar, useTranslations, useVersions } from "hooks";
import { Robot } from "hooks/useRobots";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Robots = () => {
  const [create, setCreate] = useState<boolean>(false);
  const [edit, setEdit] = useState<Robot | undefined>(undefined);
  const [confirmDelete, setConfirmDelete] = useState<Robot | undefined>(
    undefined
  );
  const { getVersion, updateVersion } = useVersions();
  const { sendSnack } = useSnackbar();
  const [robots, getRobots] = useRobots();
  const translations = useTranslations();

  const handleDeleteRobot = async () => {
    try {
      const { data } = await robotsApi.delete(`${confirmDelete?.id}`);
      sendSnack({
        message: data.responsemessage,
        type: "success",
      });
      setConfirmDelete(undefined);
      getRobots();
    } catch {
    } finally {
    }
  };
  const onSave = async () => {
    getRobots();
    updateVersion(-1);
  };
  return (
    <React.Fragment>
      <RobotDialog
        onSave={onSave}
        open={create}
        onClose={() => setCreate(false)}
      />
      <RobotDialog
        onSave={onSave}
        open={Boolean(edit)}
        defaultRobot={edit}
        onClose={() => setEdit(undefined)}
      />
      <Dialog
        open={Boolean(confirmDelete)}
        onClose={() => setConfirmDelete(undefined)}
      >
        <DialogHeader onClose={() => setConfirmDelete(undefined)}>
          {getTranslation(translations, "generic.pleaseconfirm")}
        </DialogHeader>
        <DialogContent>
          {getTranslation(translations, "robots.confirm.delete")}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmDelete(undefined)}
            variant="contained"
            size="small"
          >
            {getTranslation(translations, "generic.button.cancel")}
          </Button>
          <Button onClick={handleDeleteRobot} variant="contained" size="small">
            {getTranslation(translations, "generic.button.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
      <Stack spacing={1}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h5">
              {getTranslation(translations, "robots.name")}
            </Typography>
            <IconButton size="small" to={"help"} component={Link}>
              <Help fontSize="small" />
            </IconButton>
          </Stack>
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={() => setCreate(true)}
          >
            {getTranslation(translations, "robots.add")}
          </Button>
        </Stack>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6">
                  {getTranslation(translations, "generic.name")}
                </Typography>
              </TableCell>
              <TableCell>
                {/* <Typography variant="h6">
                  {getTranslation(translations, "generic.actions")}
                </Typography> */}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {robots.map((robot) => (
              <TableRow key={`robot-${robot.id}`}>
                <TableCell>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <AvatarRem
                      key={`${robot.id}-${getVersion(-1)}`}
                      icon={robot.picture || undefined}
                    >
                      <PrecisionManufacturing />
                    </AvatarRem>
                    <Typography variant="body2">{robot.name}</Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => setEdit(robot)} size="small">
                    <Edit fontSize="small" />
                  </IconButton>
                  <IconButton
                    onClick={() => setConfirmDelete(robot)}
                    size="small"
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Stack>
    </React.Fragment>
  );
};

export default Robots;
