import { Star } from "@mui/icons-material";
import {
  // Avatar,
  Badge,
  Divider,
  ListItemAvatar,
  ListItemButton,
  // ListItemSecondaryAction,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { getTranslation } from "common";
import { useTranslations, useUser } from "hooks";
import React from "react";
// import Thumbnail from "./Thumbnail";
import { GroupAssociation, RoleAssociation } from "./UserSelectedPanel";
// import RemoveUserPopUp from "components/dialogs/RemoveUserPopUp";
import { PrimitiveUser } from "model/userModel";
import AvatarRem from "./AvatarRem";

export interface User extends PrimitiveUser {
  administrator: boolean;
  id: number;
  organizationId: number;
  groups: Array<GroupAssociation>;
  roles: Array<RoleAssociation>;
  username: string;
}
interface UserItemProps extends User {
  getFirstUsers: Function;
  onSelect: Function;
  selected: User | null;
  userLoggedIsAdmin: boolean;
  lastRef?: (node: any) => void;
}
const getName = (
  firstname: string,
  lastname: string,
  email: string
): string => {
  return firstname && lastname ? `${lastname} ${firstname}` : email;
};
const UserItem: React.FC<UserItemProps> = ({
  onSelect,
  selected,
  administrator,
  picture,
  username,
  lastname,
  firstname,
  email,
  id,
  groups,
  roles,
  organizationId,
  lastRef,
}) => {
  const translations = useTranslations();
  const user = useUser();
  const name = getName(firstname, lastname, email);

  return (
    <React.Fragment>
      <ListItemButton
        id={`user-${id}`}
        ref={(node) => lastRef && lastRef(node)}
        selected={selected?.id === id}
        onClick={() => onSelect(selected?.id === id ? null : {
          administrator,
          lastname,
          firstname,
          email,
          picture,
          id,
          organizationId,
          groups,
          roles,
          username
        })}
      >
        {administrator ? (
          <ListItemAvatar sx={{ height: "40px", width: "40px" }}>
            <Tooltip title="admin">
              <Badge
                color="primary"
                overlap="circular"
                sx={{
                  "& .MuiBadge-badge": {
                    p: "0px !important",
                  },
                }}
                badgeContent={<Star sx={{ fontSize: "10px" }} />}
              >
                <AvatarRem imgProps={{
                  referrerPolicy: "no-referrer",
                }}
                  onMouseOver={(e) => e.stopPropagation()} icon={picture || ""} />
              </Badge>
            </Tooltip>
          </ListItemAvatar>
        ) : (
          <ListItemAvatar sx={{ height: "40px", width: "40px" }}>
            <AvatarRem icon={picture || ""} />
          </ListItemAvatar>
        )}
        <ListItemText
          primary={name}
          secondaryTypographyProps={{ component: "div" }}
          secondary={
            <Stack direction="row" spacing={1} alignItems="flex-end" >
              <Typography>
                {user?.userId === id && getTranslation(translations, "profile.myself.label")}
              </Typography>
            </Stack>
          }
        >
        </ListItemText>
        {/* <ListItemSecondaryAction
          sx={{
            display:
              selected?.id === id
                ? "none"
                : {
                  xs: "none",
                  md: "flex",
                },
          }}
        >
          <Stack alignItems="flex-start" spacing={0.5}>
            <Thumbnail
              items={groups}
              title={getTranslation(translations, "groups.name")}
            />
            <Thumbnail
              items={roles}
              title={getTranslation(translations, "roles.name")}
            />
          </Stack>

        </ListItemSecondaryAction> */}
      </ListItemButton>
      <Divider />
    </React.Fragment >
  );
};

export default UserItem;
