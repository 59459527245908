import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Box } from "@mui/system";
import { getTranslation } from "common";
import { useTranslations } from "hooks";
import Checkbox from "@mui/material/Checkbox";

interface FieldsListItemProps {
  canManage: boolean;
  item: { key: string; value: string };
  selectedProcess: number | null;
  onDelete: () => void;
  onEdit: () => void;
  onChangeInCard: (e: ChangeEvent<HTMLInputElement>, newCheck: boolean, key: string) => void;
  check: string | null;
}

const FieldsListItem: React.FC<FieldsListItemProps> = ({
  canManage,
  selectedProcess,
  onChangeInCard,
  check,
  item,
  onDelete,
  onEdit,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const translations = useTranslations();
  
  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const buttons = [
    {
      tooltip: getTranslation(translations, "generic.edit.caption"),
      onClick: onEdit,
      icon: EditRoundedIcon,
    },
    {
      tooltip: getTranslation(translations, "generic.delete.caption"),
      onClick: onDelete,
      icon: DeleteForeverRoundedIcon,
    },
  ];
  return (
    <Box>
      <ListItem>
        <ListItemText
          id="userdata-list"
          primary={
            <Box
              sx={{
                maxWidth: "50%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Tooltip
                title={`${item.key} : ${item.value}`}
                placement="bottom-start"
              >
                <span id={`userdata-${item.key}`} aria-describedby={id}>
                  {`${item.key} : ${item.value}`}
                </span>
              </Tooltip>
            </Box>
          }
        />
        <Menu
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Typography sx={{ px: 2 }}>{`${item.key}: ${item.value}`}</Typography>
        </Menu>
        {canManage && (
          <ListItemSecondaryAction>
            <Stack direction="row" spacing={2}>
              {buttons.map((button, idx) => (
                <Tooltip key={idx} title={button.tooltip}>
                  <IconButton
                    id={`button-${idx}`}
                    onClick={button.onClick}
                    sx={{
                      color: "rgba(84, 189, 193, 1)",
                      borderRadius: "6px",
                    }}
                  >
                    <button.icon />
                  </IconButton>
                </Tooltip>
              ))}
              <Box
                sx={{ "& .MuiCheckbox-root": { color: "rgba(84, 189, 193, 1)" } }}
              >
                <Tooltip title={getTranslation(translations, "generic.showingincard.caption")}>
                  <Checkbox
                    edge="end"
                    onChange={(e, newCheck) => onChangeInCard(e, newCheck, item.key)}
                    checked={check === item.key}
                    inputProps={{
                      "aria-labelledby": `checkbox-list-label-${item.key}`,
                    }}
                  />
                </Tooltip>
              </Box>
            </Stack>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </Box>
  );
};


export default FieldsListItem;
