import { organizationsApi } from "api";
import { useCallback, useEffect, useState } from "react";
import useSelectedOrganization from "./useSelectedOrganization";

export interface Robot {
  name: string;
  iduser: number;
  iduserowner: number;
  description: string;
  company: string;
  orgjsonschema: string;
  orguischema: string;
  actjsonschema: string;
  actuischema: string;
  gtwjsonschema: string;
  gtwuischema: string;
  evtjsonschema: string;
  evtuischema: string;
  apikey: string;
  published: boolean;
  urlpushnotification: string;
  picture: string | null;
  id: number;
}

const useRobots = (): [Robot[], () => Promise<void>] => {
  const [robots, setRobots] = useState<Robot[]>([]);
  const organization = useSelectedOrganization();

  const getRobots = useCallback(async () => {
    try {
      if (organization) {
        const { data } = await organizationsApi.get(`${organization.id}/robots`);
        setRobots(data.robots);
      }
    } catch { } finally { }
  }, [organization]);

  useEffect(() => {
    getRobots();
  }, [getRobots]);

  return [robots, getRobots];
};

export default useRobots;
