import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { groupsApi, organizationsApi } from "api";
import { useOutletContext } from "react-router-dom";
import { getTranslation, toDataUrl, OutletType } from "common";
import { useInfiniteScroll, useSnackbar, useTranslations, useVersions } from "hooks";
import { Edit, PeopleAlt } from "@mui/icons-material";
import { GroupDialog } from "./dialogs";
import AvatarRem from "./AvatarRem";

export interface Group {
  creation?: string;
  icon?: string | null;
  id?: number;
  idorganization?: number;
  name?: string;
}

interface GroupsPanelProps {
  onSelect: Function;
  selectedId: number | null;
  organizationId: number;
  administrator: boolean;

}
type Ref = { getGroups: Function } | undefined;

const INITIAL_PAGE = 1;
const PAGE_SIZE = 10;

const GroupsPanel = forwardRef<Ref, GroupsPanelProps>(
  ({ onSelect, selectedId, organizationId, administrator }, ref) => {
    const translations = useTranslations();
    const [groupEdit, setGroupEdit] = useState<Group | null>(null);
    const [groups, setGroups] = useState<Array<Group>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const { secondFilter: groupsFilter } = useOutletContext<OutletType>();
    const [page, setPage] = useState<number>(INITIAL_PAGE);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const { sendSnack } = useSnackbar();
    const lastGroupRef = useInfiniteScroll(setPage, loading, hasMore);
    const mount = useRef<boolean>(false);
    const { updateVersion, getVersion } = useVersions();

    const getFirstGroups = useCallback(async () => {
      setLoading(true);
      try {
        const queryPage = `?page=${INITIAL_PAGE}&pagesize=${PAGE_SIZE}`;
        const filter = `&name=${groupsFilter}`;
        const { data } = await organizationsApi.get(
          `${organizationId}/groups/v2${queryPage}${filter}`
        );
        setHasMore(Number(data.pagination.curr_page) < Number(data.pagination.total_pages));
        setGroups([...data.groups]);
        setPage(INITIAL_PAGE);
      } catch {
      } finally {
        setLoading(false);
      }
    }, [organizationId, groupsFilter]);

    const getGroups = useCallback(
      async (query: string) => {
        if (page !== INITIAL_PAGE) {
          setLoading(true);
          try {
            const queryPage = `?page=${page}&pagesize=${PAGE_SIZE}`;
            const filter = `&name=${query}`;
            const { data } = await organizationsApi.get(
              `${organizationId}/groups/v2${queryPage}${filter}`
            );
            setHasMore(Number(data.pagination.curr_page) < Number(data.pagination.total_pages));
            setGroups((_groups) => [..._groups, ...data.groups]);
          } catch {
          } finally {
            setLoading(false);
          }
        }
      },
      [organizationId, page]
    );

    useImperativeHandle(
      ref,
      () => ({
        getGroups: getFirstGroups,
      }),
      [getFirstGroups]
    );

    useEffect(() => {
      getGroups(groupsFilter);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getGroups]);

    useEffect(() => {
      if (organizationId && mount.current) {
        getFirstGroups();
      }
      else if (!mount.current) {
        mount.current = true;
        getFirstGroups();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFirstGroups]);

    // useEffect(() => {
    //   if (!mount.current) {
    //     mount.current = true;
    //     getFirstGroups();
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [organizationId]);

    useEffect(() => {
      return () => {
        mount.current = false;
        setPage(INITIAL_PAGE);
        setGroups([]);
      };
    }, [organizationId]);

    const handleSaveEdit = async (
      imageUrl: string | null,
      name: string | null
    ) => {
      try {
        let icon = null;
        if (imageUrl) {
          icon = await toDataUrl(imageUrl);
        }
        const updateApi = await groupsApi.put(`${groupEdit?.id}`, { name, icon });
        updateVersion(groupEdit?.id);
        setGroupEdit(null);
        sendSnack({
          message: `${updateApi.data.responsemessage}`,
          type: "success",
        });
        getFirstGroups();
      } catch {
      } finally {
      }
    };

    const handleDeleteGroup = async () => {
      if (groupEdit) {
        try {
          const deleteApi = await groupsApi.delete(`${groupEdit.id}`);
          sendSnack({
            message: `${deleteApi.data.responsemessage}`,
            type: "success",
          });
          getFirstGroups();
          setGroupEdit(null);
        } catch {
        } finally {
        }
      }
    };

    return (
      <React.Fragment>
        <Stack height="100%" overflow="auto" flex={1} spacing={2}>
          <Stack height="100%">
            <Typography variant="subtitle1">
              {getTranslation(translations, "groups.name")}
            </Typography>
            <Divider flexItem sx={{ mb: 1 }} />
            <List id="groups-list" sx={{ overflow: "auto" }} disablePadding>
              {/* .filter(_ => _.name?.toLowerCase().includes(groupsFilter.toLowerCase())) */}
              {groups.map(({ icon, name, id, ...rest }, idx) => (
                <React.Fragment key={`group-${id}-${getVersion(id)}`}>
                  {groups.length === idx + 1 ? (
                    <ListItemButton
                      id={`group-${id}`}
                      ref={(node) => lastGroupRef(node)}
                      selected={selectedId === id}
                      onClick={() => onSelect(selectedId === id ? null : id)}
                    >
                      <ListItemAvatar sx={{ height: "40px", width: "40px" }}>
                        <AvatarRem icon={icon || ""}>
                          <PeopleAlt />
                        </AvatarRem>
                      </ListItemAvatar>
                      <ListItemText>{name}</ListItemText>
                      {selectedId === id && (administrator) && (
                        <ListItemSecondaryAction>
                          <Tooltip title={getTranslation(translations, "generic.edit_group.caption")}>
                            <IconButton
                              id={`btn-modify-group-${id}`}
                              size="medium"
                              color="secondary"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setGroupEdit({ icon, name, id, ...rest });
                              }}
                            >
                              <Edit fontSize="medium" />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      )}
                    </ListItemButton>
                  ) : (
                    <ListItemButton
                      id={`group-${id}`}
                      selected={selectedId === id}
                      onClick={() => onSelect(selectedId === id ? null : id)}
                    >
                      <ListItemAvatar sx={{ height: "40px", width: "40px" }}>
                        <AvatarRem icon={icon || ""}>
                          <PeopleAlt />
                        </AvatarRem>
                      </ListItemAvatar>
                      <ListItemText>{name}</ListItemText>
                      {selectedId === id && (administrator) && (
                        <ListItemSecondaryAction>
                          <Tooltip title= {getTranslation(translations,"generic.edit_group.caption")}>
                            <IconButton
                              id={`btn-modify-group-${id}`}
                              size="medium"
                              color="secondary"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setGroupEdit({ icon, name, id, ...rest });
                              }}
                            >
                              <Edit fontSize="medium" />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      )}
                    </ListItemButton>
                  )}
                </React.Fragment>
              ))}
              {loading && (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              )}
            </List>
          </Stack>
        </Stack>
        <GroupDialog
          open={Boolean(groupEdit)}
          group={groupEdit}
          onDelete={handleDeleteGroup}
          onClose={() => setGroupEdit(null)}
          onSave={handleSaveEdit}
        />
      </React.Fragment >
    );
  }
);

export default GroupsPanel;
