import { AddCircleRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  List,
  TextField,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import DialogHeader from "./dialogs/DialogHeader";
import { getTranslation } from "common";
import { useForm, useTranslations } from "hooks";
import { Validation, isRequired } from "hooks/useForm";
import FieldsListItem from "./FieldsListItem";

interface FieldsListProps {
  canManage?: boolean;
  onChange: (newValues: { [key: string]: string }) => void;
  value: { [key: string]: string };
  selectedProcess: number | null;
  handleSaveUserdata?: (newUserdata: { [key: string]: string }, checked: string | null) => void;
}

const FieldsList: React.FC<FieldsListProps> = ({
  canManage = true,
  selectedProcess,
  onChange,
  value,
  handleSaveUserdata,
}) => {
  const [check, setCheck] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState<string | null>(null);
  const translations = useTranslations();
  const validations: Validation[] = [
    ({ key }) =>
      isRequired(key) || {
        key: getTranslation(translations, "register.fieldrequired"),
      },
    ({ value }) =>
      isRequired(value) || {
        value: getTranslation(translations, "register.fieldrequired"),
      },
    ({ key }) =>
      Boolean(edit) ||
      !(key in value) || { key: getTranslation(translations, "generic.keypresent.metadata.caption") },
  ];
  const { values, changeHandler, errors, touched, isValid, reset, setValues } =
    useForm(
      {
        key: "",
        value: "",
      },
      validations
    );
  const onChangeInCard = (_: ChangeEvent, newCheck: boolean, key: string) => {
    const newValue = newCheck ? key : null
    localStorage.setItem(`userdata-per-${selectedProcess}`, newValue || "");
    setCheck(newValue);
    handleSaveUserdata && handleSaveUserdata(value, newValue);
  };
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    let newUserdata;
    if (edit) {
      const { [edit]: _, ...newValue } = { ...(value as any) };
      newUserdata = { ...newValue, [values.key]: values.value };
    } else {
      newUserdata = { ...value, [values.key]: values.value };
    }
    onChange(newUserdata);
    setOpen(false);
    handleSaveUserdata && handleSaveUserdata(newUserdata, check);
  };

  const onDelete = (key: string) => {
    const { [key]: _, ...newValue } = { ...(value as any) };
    onChange(newValue);
    handleSaveUserdata && handleSaveUserdata(newValue, check);
  };
  const onEdit = (key: string, _value: string) => {
    setOpen(true);
    setValues({ key, value: _value });
    setEdit(key);
  };

  useEffect(() => {
    setCheck(localStorage.getItem(`userdata-per-${selectedProcess}`))
    if (!open) {
      reset();
      setEdit(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return (
    <Box>
      {canManage && (
        <span
          style={{
            cursor: "not-allowed",
            textAlign: "left",
          }}
        >
          <Button
            id="addfield-button"
            endIcon={<AddCircleRounded />}
            variant="text"
            size="small"
            onClick={() => setOpen(true)}
          >
            {getTranslation(translations, "pm.mayactivities.addfield.label")}
          </Button>
        </span>
      )}
      <List
        sx={{
          maxHeight: "100px",
          maxWidth: "450px",
          overflowY: "hidden",
          marginTop: "10px",
        }}
      >
        {Object.entries(value).map(([key, _value]) => (
          <FieldsListItem
            canManage={canManage}
            onChangeInCard={onChangeInCard}
            check={check}
            selectedProcess={selectedProcess}
            key={`fields-list-item-${key}`}
            item={{ key, value: _value }}
            onDelete={() => onDelete(key)}
            onEdit={() => onEdit(key, _value)}
          />
        ))}
      </List>
      {/* <Button id="save-userdata-btn" size="small" variant="contained" color="primary" onClick={handleSaveUserdata}>
        Salva Userdata (n.t)
      </Button> */}

      <Dialog open={open} onClose={() => setOpen(false)}>
        <form>
          <DialogHeader onClose={() => setOpen(false)}>
            {getTranslation(translations, "pm.mayactivities.addfield.label")}
          </DialogHeader>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6}>
                <TextField
                  id="key-field"
                  size="small"
                  label={getTranslation(
                    translations,
                    "pm.mayactivities.key.label"
                  )}
                  fullWidth
                  error={Boolean(touched.key && errors.key)}
                  helperText={touched.key && errors.key}
                  value={values.key}
                  onChange={({ target }) => changeHandler("key", target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="value-field"
                  size="small"
                  label={getTranslation(
                    translations,
                    "placeholder.value.label"
                  )}
                  fullWidth
                  error={Boolean(touched.value && errors.value)}
                  helperText={touched.value && errors.value}
                  value={values.value}
                  onChange={({ target }) =>
                    changeHandler("value", target.value)
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button size="small" onClick={() => setOpen(false)}>
              {getTranslation(
                translations,
                "pm.startprocess.button.cancel.caption"
              )}
            </Button>
            <Button
              id="confirm-field-button"
              variant="contained"
              size="small"
              type="submit"
              disabled={!isValid}
              onClick={handleSubmit}
            >
              {getTranslation(
                translations,
                "pm.mayactivities.confirmation.label"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default FieldsList;
