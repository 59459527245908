import { Card, CardContent, CardHeader, Container, Grid, Skeleton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'

interface AuthFlowLayoutProps extends React.PropsWithChildren {
    title: string;
    image: string;
    alt: string;
    cardTitle: string;
    rightAltImage: string;
    rightImage: string;
}


const AuthFlowLayout: React.FC<AuthFlowLayoutProps> = ({ rightAltImage, rightImage, title, image, alt, cardTitle, children }) => {
    // const [loading, setIsLoading] = useState(true)
    return (
        <Container maxWidth="lg" sx={{ overflow: "hidden" }}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10, marginBottom: 20 }}>
                <Box
                    component="img"
                    alt={alt}
                    style={{
                        width: "100px",
                        height: "100px",
                        marginRight: "12px",
                    }}
                    src={image}
                />
                <Typography color="black" fontSize="50px">
                    {title}
                </Typography>
            </Box>
            <Grid container spacing={2} alignItems="flex-start" justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Card
                        sx={{
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            width: 300,
                            marginLeft: "auto",
                            marginRight: "auto"
                        }}
                    >
                        <CardHeader title={cardTitle} />
                        <CardContent>
                            {children}
                        </CardContent>
                    </Card>

                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Box
                        // onLoad={() => setTimeout(() => setIsLoading(false), 5000)}
                        component="img"
                        alt={rightAltImage}
                        src={rightImage}
                        sx={{
                            width: 'auto',
                            maxWidth: "100%",
                            maxHeight: "400px"
                        }}
                    />
                    {/* {loading && <Skeleton variant="rectangular" width={"100%"} height={"100%"} />} */}
                </Grid>
            </Grid>
        </Container>
    )
}

export default AuthFlowLayout